import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { glossarySelector } from 'src/redux/glossary/selector';
import { supabaseSelector } from 'src/redux/supabase/selector';
import { useTranslation } from 'react-i18next';
import {
  createNewGlossary,
  deleteGlossary,
  emptyStateValueGlossary,
  getGlossaryTags,
  getHardWords,
  ownExplanations,
  searchAIGlossery,
  searchGlossery,
  setDifficultWords,
  setDifficultWordsToShow,
  setSelectedTagsList,
  showAiSearchUnauthAlert,
} from 'src/redux/glossary/actions';
import { appMessageHandling } from 'src/utils/errorHandler';
import { refreshSession } from 'src/redux/supabase/actions';
import { UserSelector } from 'src/redux/user/selector';
import { updateGlossaryEntry } from 'src/redux/glossaryEntries/actions';
import { Link } from 'react-router-dom';
import { BoxArrowUpRight } from 'react-bootstrap-icons';
import {
  DifficultWords,
  GlossaryReducers,
} from '@summ-ai-github/summ-frontend-package/components/glossaryFieldComp';
import GlossaryField from '@summ-ai-github/summ-frontend-package/components/glossaryFieldComp';
import { GlossaryData } from 'src/types/glossaryTypes.types';
import { isEmpty } from 'lodash';
type Props = {
  outputText: string;
  switchValue: boolean;
};

const GlossaryFieldsWrapper = ({ switchValue }: Props) => {
  const { t } = useTranslation(['glossary', 'common'], {
    nsMode: 'fallback',
  });
  const dispatch: any = useDispatch();
  const supabaseUser = useSelector(
    (state: any) => supabaseSelector(state).user,
    shallowEqual
  );

  const glosseryItems = useSelector(
    (state: any) => glossarySelector(state).glossaryItems,
    shallowEqual
  );

  const AIWordExplanation = useSelector(
    (state: any) => glossarySelector(state).AIWordExplanation,
    shallowEqual
  );

  const hardWords = useSelector(
    (state: any) => glossarySelector(state).hardWords,
    shallowEqual
  );

  const glossaryTags = useSelector(
    (state: any) => glossarySelector(state).glossaryTags,
    shallowEqual
  );
  const loading = useSelector(
    (state: any) => glossarySelector(state).loading,
    shallowEqual
  );

  const loadingAI = useSelector(
    (state: any) => glossarySelector(state).loadingAI,
    shallowEqual
  );
  const selectedTagsList = useSelector(
    (state: any) => glossarySelector(state).selectedTagsList,
    shallowEqual
  );
  const difficultWords = useSelector(
    (state: any) => glossarySelector(state).difficultWords,
    shallowEqual
  );
  const difficultWordsToShow = useSelector(
    (state: any) => glossarySelector(state).difficultWordsToShow,
    shallowEqual
  );
  const otherWordExplanation = useSelector(
    (state: any) => glossarySelector(state).otherWordExplanation,
    shallowEqual
  );

  const unAuthAlert = useSelector(
    (state: any) => glossarySelector(state).unAuthAlert,
    shallowEqual
  );

  const userDetails: any = useSelector(
    (state: any) => UserSelector(state).userDetails,
    shallowEqual
  );

  return (
    <GlossaryField
      noTextSelectedWord={t('noTextSelected')}
      aiGeneratedWord={t('ai.aiGenerated')}
      maxAILimitWord={t('glossaryAlerts.maxAIWordLimit')}
      myGlossaryWord={t('glossaryEntries.myGlossary')}
      exportLinkWord={
        <Link to="/glossary-entries">
          {t('glossaryEntries.exportLink')}{' '}
          <BoxArrowUpRight
            style={{ marginBottom: '5px' }}
            fontSize={'small'}
          />
        </Link>
      }
      searchGlossaryWord={t('searchGlossary')}
      searchWord={t('search')}
      fromWord={t('from')}
      addGlossaryWord={t('ai.addGlossary')}
      supabaseUser={supabaseUser}
      createNewGlossary={(value: GlossaryData) => {
        dispatch(createNewGlossary(value));
      }}
      glosseryItems={glosseryItems}
      loading={loading || loadingAI}
      setSelectedTagsList={(value: string) => {
        dispatch(setSelectedTagsList(value));
      }}
      emptyStateValueGlossary={(value: GlossaryReducers) => {
        dispatch(emptyStateValueGlossary(value));
      }}
      getGlossaryTags={() => {
        dispatch(getGlossaryTags());
      }}
      setDifficultWordsToShow={(value: any) => {
        dispatch(setDifficultWordsToShow(value));
      }}
      setDifficultWords={(value: DifficultWords[]) => {
        dispatch(setDifficultWords(value));
      }}
      appMessageHandling={() => {
        appMessageHandling(dispatch, t('glossaryAlerts.textTooLong'), 'error');
      }}
      refreshSession={() => {
        dispatch(refreshSession());
      }}
      searchGlossery={(value: any, searchInput) => {
        dispatch(searchGlossery(value));

        // Search for word with explanation after finish fetching AI explanation
        if (
          !searchInput &&
          userDetails.preferred_output_language_type === 'easy'
        ) {
          dispatch(
            getHardWords({
              text: value.inputText,
              with_explanations: false,
              use_cached_explanations: true,
            })
          );

          // Search for explanation after finish fetching the words
          dispatch(
            getHardWords({
              text: value.inputText,
              with_explanations: true,
              use_cached_explanations: true,
            })
          );
        }
      }}
      ownExplanations={() => {
        dispatch(ownExplanations());
      }}
      searchAIGlossery={(value: any) => {
        dispatch(searchAIGlossery(value));
      }}
      premiumFeatureAlertWord={t('premiumFeatureAlert')}
      newEntryWord={t('newEntry')}
      wordText={t('word')}
      enterTags={t('enterTags')}
      filterTagText={t('glossaryEntries.headers.tags')}
      explanationWord={t('explanation')}
      enterWord={t('enter')}
      userDetails={userDetails}
      AIUnauthWord={t('glossaryAlerts.AIUnauth', {
        interpolation: { escapeValue: false },
      })}
      showAiSearchUnauthAlert={(value: boolean) => {
        dispatch(showAiSearchUnauthAlert(value));
      }}
      glossaryForWord={t('glossaryForWord')}
      editExplanationForWord={t('editExplanationFor')}
      updateGlossaryEntry={(value: any) => {
        dispatch(updateGlossaryEntry(value));
      }}
      deleteConfirm1Word={t('deleteConfirm1')}
      deleteConfirm2Word={t('deleteConfirm2')}
      deleteGlossary={(id: string) => {
        dispatch(deleteGlossary(id));
      }}
      difficultWordsToShow={difficultWordsToShow}
      selectedTagsList={selectedTagsList}
      difficultWords={difficultWords}
      glossaryTags={glossaryTags}
      AIWordExplanation={
        isEmpty(AIWordExplanation) && glosseryItems
          ? hardWords
          : AIWordExplanation
      }
      otherWordExplanation={otherWordExplanation}
      unAuthAlert={unAuthAlert}
      summaryStyle={{
        '&.MuiAccordionSummary-content': {
          margin: '0 !important',
        },
      }}
      noCancelWord={t('cancel')}
      yesDeleteWord={t('confirm')}
      copiedText={t('copied')}
      copyText={t('copy')}
      switchValue={switchValue}
    />
  );
};

export default GlossaryFieldsWrapper;
