import { Dispatch, SetStateAction } from 'react';
import { Form } from 'react-bootstrap';
import { FeedbackText } from '../../fixedData';
import ShowNegativeFeedback from './ShowNegativeFeedback';
import { useTranslation } from 'react-i18next';
import {
  ButtonGroup,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ThumbDownAltRoundedIcon from '@mui/icons-material/ThumbDownAltRounded';
import CachedIcon from '@mui/icons-material/Cached';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  emptyStateValueTrans,
  regenerateTranslation,
} from 'src/redux/translation/actions';
import { TranslateSelector } from 'src/redux/translation/selector';
import { UserSelector } from 'src/redux/user/selector';
import ShowRegenerateFeedback from './ShowRegenerateFeedback';
import { editorValueClear, editorValueUpdate } from '../../helper';
type Props = {
  setShowTooltipOutput(value: boolean): void;
  outputRefEditor: any;
  setShowFeedbackButtons(value: boolean): void;
  setShowSubmitAdjustedButton: Dispatch<SetStateAction<boolean>>;
  updateSimpleTextUserEdited: (
    value: FeedbackText,
    userFeedbackText?: string
  ) => void;
  transIndex: number;
  setTransIndex: Dispatch<SetStateAction<number>>;
  showFeedbackModal: boolean;
  setShowFeedbackModal: Dispatch<SetStateAction<boolean>>;
  showRegenerateModal: boolean;
  setShowRegenerateModal: Dispatch<SetStateAction<boolean>>;
};

const FeedbackButtonsComp = ({
  setShowTooltipOutput,
  outputRefEditor,
  setShowFeedbackButtons,
  setShowSubmitAdjustedButton,
  updateSimpleTextUserEdited,
  transIndex,
  setTransIndex,
  showFeedbackModal,
  setShowFeedbackModal,
  showRegenerateModal,
  setShowRegenerateModal,
}: Props) => {
  const theme = useTheme();
  const dispatch: any = useDispatch();
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));

  const transjob = useSelector(
    (state: any) => TranslateSelector(state).transjob,
    shallowEqual
  );

  const autoLangDetect = useSelector(
    (state: any) => TranslateSelector(state).autoLangDetect,
    shallowEqual
  );

  const transConfig = useSelector(
    (state: any) => TranslateSelector(state).transConfig,
    shallowEqual
  );

  const userDetails = useSelector(
    (state: any) => UserSelector(state).userDetails,
    shallowEqual
  );

  const regeneratedJobs = useSelector(
    (state: any) => TranslateSelector(state).regeneratedJobs,
    shallowEqual
  );

  const disableLikeDislike = useSelector(
    (state: any) => TranslateSelector(state).disableLikeDislike,
    shallowEqual
  );

  const handleAdjustment = () => {
    // show tooltip over output field and highlight box
    setShowTooltipOutput(true);
    outputRefEditor.current.focus();

    setTimeout(() => {
      setShowTooltipOutput(false);
    }, 10 * 1000);

    // hide all Feedback buttons
    setShowFeedbackButtons(false);

    // show new button for submit
    setShowSubmitAdjustedButton(true);
  };

  const handleGenerateNewTrans = () => {
    editorValueClear(outputRefEditor, dispatch);
    editorValueUpdate(
      t('output.textarea.loading'),
      outputRefEditor,
      'translation-output'
    );

    dispatch(
      regenerateTranslation({
        is_regenerated: 'True',
        initial_job_id: Number(transjob.jobId),
        outputLanguage: userDetails?.preferred_output_language_type,
        separator_style: userDetails
          ? userDetails[
              `preferred_style_${
                userDetails.preferred_output_language_type ?? 'easy'
              }`
            ]
          : 'hyphen',
        translationLanguage: autoLangDetect?.value ?? 'de',
        usedPlatform: 'SUMM webapp',
        inputText: transjob.inputText,
        is_new_lines:
          transConfig?.[
            `preferred_new_lines_${
              userDetails?.preferred_output_language_type ?? 'plain'
            }`
          ],
        input_text_type: userDetails?.format_input_switch
          ? 'html'
          : 'plain_text',
      })
    );
  };

  const handleSetTransJob = (index: number) => {
    setTransIndex(index);
    dispatch(
      emptyStateValueTrans({
        transjob: regeneratedJobs[index],
      })
    );
  };

  return (
    <>
      <Form.Group
        className="feedbackContainer"
        style={{
          paddingLeft: !matchesMD ? '24px' : '0',
        }}
      >
        {regeneratedJobs.length > 1 && (
          <ButtonGroup
            size="small"
            aria-label="Small button group"
            sx={{
              display: 'inline-flex',
              verticalAlign: 'bottom',
            }}
          >
            <IconButton
              onClick={() => {
                handleSetTransJob(transIndex - 1);
              }}
              sx={{
                background: theme.palette.general.white,
                color: theme.palette.primary.main,
                textTransform: 'none',
                fontSize: '1rem',
                borderRadius: '5px 0 0 5px',
                '&:hover': {
                  background: theme.palette.general.white,
                  color: theme.palette.primary.main,
                },
                '&:disabled': {
                  background: theme.palette.general.white,
                },
              }}
              disabled={transIndex === 0}
            >
              <ChevronLeftIcon
                style={{
                  strokeWidth: 2,
                  fontSize: '1.2rem',
                  fill: 'none',
                  stroke: theme.palette.primary.main,
                }}
              />
            </IconButton>

            <Typography
              variant="body2"
              sx={{
                userSelect: 'none',
                background: theme.palette.general.white,
                margin: 0,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {transIndex + 1}/{regeneratedJobs.length}
            </Typography>
            <IconButton
              onClick={() => {
                handleSetTransJob(transIndex + 1);
              }}
              sx={{
                background: theme.palette.general.white,
                color: theme.palette.primary.main,
                textTransform: 'none',
                fontSize: '1rem',
                marginRight: 1,
                borderRadius: '0 5px 5px 0',
                '&:hover': {
                  background: theme.palette.general.white,
                  color: theme.palette.primary.main,
                },
                '&:disabled': {
                  background: theme.palette.general.white,
                },
              }}
              disabled={transIndex === regeneratedJobs.length - 1}
            >
              <KeyboardArrowRightIcon
                style={{
                  strokeWidth: 2,
                  fontSize: '1.2rem',
                  fill: 'none',
                  stroke: theme.palette.primary.main,
                }}
              />
            </IconButton>
          </ButtonGroup>
        )}
        <Tooltip
          children={
            <IconButton
              onClick={() => updateSimpleTextUserEdited('positive')}
              sx={{
                background: theme.palette.general.white,
                color: theme.palette.primary.main,
                textTransform: 'none',
                fontSize: '1rem',
                marginRight: 1,
                borderRadius: '5px',
                '&:hover': {
                  background: theme.palette.general.white,
                  color: theme.palette.primary.main,
                },
                '&:disabled': {
                  background: theme.palette.general.white,
                },
              }}
              disabled={disableLikeDislike[transjob.jobId]}
            >
              <ThumbUpAltRoundedIcon
                style={{
                  strokeWidth: 2,
                  fontSize: '1.2rem',
                  fill: 'none',
                  stroke: disableLikeDislike[transjob.jobId]
                    ? theme.palette.general.lightBlue
                    : theme.palette.primary.main,
                }}
              />
            </IconButton>
          }
          title={t('feedback.positive.buttonText')}
          arrow
          placement="top"
        />
        <Tooltip
          children={
            <IconButton
              onClick={() => {
                setShowFeedbackModal(true);
                // setShowRegenerateModal(false);
              }}
              sx={{
                background: theme.palette.general.white,
                color: theme.palette.primary.main,
                textTransform: 'none',
                fontSize: '1rem',
                marginRight: 1,
                borderRadius: '5px',
                '&:hover': {
                  background: theme.palette.general.white,
                  color: theme.palette.primary.main,
                },
                '&:disabled': {
                  background: theme.palette.general.white,
                },
              }}
              disabled={disableLikeDislike[transjob.jobId]}
            >
              <ThumbDownAltRoundedIcon
                style={{
                  strokeWidth: 2,
                  fontSize: '1.2rem',
                  fill: 'none',
                  stroke: disableLikeDislike[transjob.jobId]
                    ? theme.palette.general.lightBlue
                    : theme.palette.primary.main,
                }}
              />
            </IconButton>
          }
          title={t('feedback.negative.buttonText')}
          arrow
          placement="top"
        />

        <Tooltip
          children={
            <IconButton
              onClick={() => {
                handleGenerateNewTrans();
                setShowRegenerateModal(true);
                setShowFeedbackModal(false);
              }}
              sx={{
                background: theme.palette.general.white,
                color: theme.palette.primary.main,
                textTransform: 'none',
                fontSize: '1rem',
                marginRight: 1,
                borderRadius: '5px',
                '&:hover': {
                  background: theme.palette.general.white,
                  color: theme.palette.primary.main,
                },
                '&:disabled': {
                  background: theme.palette.general.white,
                },
              }}
              disabled={regeneratedJobs.length > 1}
            >
              <CachedIcon
                style={{
                  fontSize: '1.2rem',
                  strokeWidth: 2,
                }}
              />
            </IconButton>
          }
          title={t('feedback.regerate.buttonText')}
          arrow
          placement="top"
        />
        <Tooltip
          children={
            <IconButton
              onClick={handleAdjustment}
              sx={{
                background: theme.palette.general.white,
                color: theme.palette.primary.main,
                textTransform: 'none',
                fontSize: '1rem',
                borderRadius: '5px',
                '&:hover': {
                  background: theme.palette.general.white,
                  color: theme.palette.primary.main,
                },
              }}
            >
              <EditRoundedIcon
                style={{
                  fontSize: '1.2rem',
                  fill: 'none',
                  stroke: theme.palette.primary.main,
                  strokeWidth: 2,
                }}
              />
            </IconButton>
          }
          title={t('feedback.edit.buttonText')}
          arrow
          placement="top"
        />
      </Form.Group>
      {showFeedbackModal && (
        <ShowNegativeFeedback
          showFeedbackModal={showFeedbackModal}
          setShowFeedbackModal={setShowFeedbackModal}
          updateSimpleTextUserEdited={updateSimpleTextUserEdited}
        />
      )}

      {showRegenerateModal && (
        <ShowRegenerateFeedback
          showFeedbackModal={showRegenerateModal}
          setShowFeedbackModal={setShowRegenerateModal}
        />
      )}
    </>
  );
};

export default FeedbackButtonsComp;
