import React from 'react';
import { Popover } from 'react-bootstrap';
import styles from './HelpSection.module.css';
import { useTranslation } from 'react-i18next';
import {
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  useTheme,
} from '@mui/material';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: '22em',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    borderRadius: '10px',
    marginBottom: 0,
    padding: 0,
  },
}));

const HelpSection = () => {
  const theme = useTheme();
  const { t } = useTranslation('common');
  const [open, setOpen] = React.useState(false);

  const handleTooltipView = () => {
    setOpen(!open);
  };

  return (
    <div style={{ color: `${theme.palette.primary.main} !important` }}>
      <HtmlTooltip
        title={
          <Popover id="popover-contained">
            <Popover.Title as="h3">{t('help.contact.title')}</Popover.Title>
            <Popover.Content>
              <strong>Email:</strong>{' '}
              <a href="mailto:team@summ-ai.com">team@summ-ai.com</a>
              <br />
              <strong>{t('help.contact.phone')}:</strong> +49 178 3674624
            </Popover.Content>
          </Popover>
        }
        placement="top"
        onClose={handleTooltipView}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        slotProps={{
          popper: {
            disablePortal: true,
          },
        }}
      >
        <p
          className={styles['help-link']}
          onClick={handleTooltipView}
        >
          {t('help.help')}
        </p>
      </HtmlTooltip>
      <p style={{ marginBottom: 0 }}>{t('feedback')}</p>
      <a
        href="mailto:feedback@summ-ai.com?subject=Feedback an SUMM&body=Hallo liebes SUMM-Team,%0D%0A%0D%0Aich habe folgendes Feedback:%0D%0A%0D%0A"
        style={{
          textDecoration: 'none',
          color: theme.palette.primary.main,
        }}
      >
        feedback@summ-ai.com
      </a>
    </div>
  );
};

export default HelpSection;
