import ImageUploadCompWrapper from './ImageSearchCompWrapper';
import Box from '@mui/material/Box';
import ImageGenerationWrapper from './ImageGenerationCompWrapper';
import ImageSectionPanel from './ImageSectionPanel';
import { useTranslation } from 'react-i18next';
import { generateImages, showMoreImageSearchResults } from '../../redux/addImage/actions';
import { useDispatch } from 'react-redux';


type Props = {};

const ImageSelectionComp = (props: Props) => {
  const dispatch: any = useDispatch();
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <ImageSectionPanel
        title={t('image.myImages')}
        expandTitle={t('image.editPrompt')}
        moreTitle={t('image.searchMore')}
        contentCreator={(props) => <ImageUploadCompWrapper {...props} />}
        onMoreAction={() => dispatch(showMoreImageSearchResults())}
      />
      <Box
      sx={{
        mt: 7,
        mb: 2
      }}>
        <ImageSectionPanel
          title={t('image.AIImages')}
          expandTitle={t('image.editPrompt')}
          moreTitle={t('image.generateMore')}
          contentCreator={(props) => <ImageGenerationWrapper {...props} />}
          onMoreAction={() => dispatch(generateImages(false))}
        />
      </Box>
    </Box>
  );
};

export default ImageSelectionComp;
