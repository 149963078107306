import {
  GET_ALL_UPLOADED_IMAGE,
  GENERATION_LOADING,
  SEARCH_LOADING,
  SEARCH_UPLOADED_IMAGE,
  STOP_GENERATION_LOADING,
  STOP_SEARCH_LOADING,
  UPLOAD_IMAGE,
  UPLOAD_LIST_IMAGE,
  RESET_IMAGE_UPLOAD_STATE,
  SET_UPLOAD_IMAGE_LIST,
  FEEDBACK_IMAGE,
  DELETE_IMAGE,
  DELETE_IMAGE_SET,
  GENERATED_IMAGE_RECEIVED,
  SET_IMAGE_GENERATION_TEXT,
  SET_IMAGE_SEARCH_TEXT,
  SET_ALL_IMAGE_SEARCH_RESULT, SEARCHED_IMAGES_RECEIVED, IMAGE_ADDED_TO_TABLE, IMAGE_REMOVED_FROM_TABLE, RESET_TABLE_IMAGES,
} from './types';

const initialState: any = {
  imageList: null,
  uploadedImages: null,
  allImageResult: null,
  generationLoading: false,
  searchLoading: false,
  uploadedImageList: {},
  tempImageList: {},
  heightlight: false,
  deletedImgId: null,
  generatedImages: null,
  generatedImagesHighlight: false,
  imageSearchText: "",
  imageGenerationText: "",
  tableNodesWithImages: []
};

export default function alertReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case UPLOAD_IMAGE:
      return {
        ...state,
        imageList: null,
        uploadedImageList: {
          ...state.uploadedImageList,
          [payload.id]: payload,
        },
      };
    case UPLOAD_LIST_IMAGE:
      let updatedList = { ...state.uploadedImageList };
      if (updatedList[payload]) delete updatedList[payload];
      return {
        ...state,
        uploadedImageList: updatedList,
      };
    case GET_ALL_UPLOADED_IMAGE:
      return {
        ...state,
        imageList: payload,
      };
    case SET_ALL_IMAGE_SEARCH_RESULT: {
      return {
        ...state,
        allImageResult: payload?.list,
      }
    }
    case SEARCHED_IMAGES_RECEIVED: {
      let imageList = {};
      let sourceList = {
        ownerList: {},
        otherList: {},
      };
      if (payload && payload.list) {
        payload.list?.forEach((item: any) => {
          item.images?.forEach((image: any) => {
            imageList = {
              ...imageList,
              [image.uuid]: image,
            };
          });
          if (item.is_owner) {
            sourceList = {
              ...sourceList,
              ownerList: {
                ...sourceList.ownerList,
                [item.id]: { ...item, images: imageList },
              },
            };
          } else {
            sourceList = {
              ...sourceList,
              otherList: {
                ...sourceList.otherList,
                [item.id]: { ...item, images: imageList },
              },
            };
          }
          imageList = {};
        });
      }
      return {
        ...state,
        imageList: payload?.list?.length > 0 ? sourceList : {},
      };
    }
    case SEARCH_UPLOADED_IMAGE: {
      let imageList = {};
      let sourceList = {
        ownerList: {},
        otherList: {},
      };
      if (payload && payload.list) {
        payload.list?.forEach((item: any) => {
          item.images?.forEach((image: any) => {
            imageList = {
              ...imageList,
              [image.uuid]: image,
            };
          });
          if (item.is_owner) {
            sourceList = {
              ...sourceList,
              ownerList: {
                ...sourceList.ownerList,
                [item.id]: { ...item, images: imageList },
              },
            };
          } else {
            sourceList = {
              ...sourceList,
              otherList: {
                ...sourceList.otherList,
                [item.id]: { ...item, images: imageList },
              },
            };
          }
          imageList = {};
        });
      }
      return {
        ...state,
        uploadedImages: payload?.list?.length > 0 ? sourceList : {},
      };
    }
    case GENERATED_IMAGE_RECEIVED: {
      if (payload?.list?.length <= 0) {
        return state;
      }

      if (payload.replace){
        return {
          ...state,
          generatedImages: payload?.list,
        };
      }else{
        let newList = []
        if (state.generatedImages && state.generatedImages.length > 0){
          newList = state.generatedImages;
        }

        newList = newList.concat(payload?.list);
        return {
          ...state,
          generatedImages: newList,
        };
      }

    }
    case SET_UPLOAD_IMAGE_LIST:
      return {
        ...state,
        tempImageList: payload,
      };
    case FEEDBACK_IMAGE: {
      let listType = payload.isOwner ? 'ownerList' : 'otherList';
      let oldImageList = { ...state.imageList[listType] };
      oldImageList[payload.name] = {
        ...oldImageList[payload.name],
        images: {
          ...oldImageList[payload.name].images,
          [payload.uuid]: {
            ...oldImageList[payload.name].images[payload.uuid],
            feedback: payload.feedback,
          },
        },
      };
      return {
        ...state,
        imageList: { ...state.imageList, [listType]: { ...oldImageList } },
      };
    }
    case DELETE_IMAGE: {
      let listType = payload.isOwner ? 'ownerList' : 'otherList';
      let oldImageList = { ...state.imageList[listType] };
      let imageListItem = {
        ...oldImageList[payload.name].images,
      };

      delete imageListItem[payload.id];
      oldImageList[payload.name] = {
        ...oldImageList[payload.name],
        images: {
          ...imageListItem,
        },
      };
      return {
        ...state,
        imageList: { ...state.imageList, [listType]: { ...oldImageList } },
      };
    }
    case SEARCH_LOADING:
      return {
        ...state,
        searchLoading: true,
      };
    case GENERATION_LOADING:
      return {
        ...state,
        generationLoading: true,
      };
    case STOP_SEARCH_LOADING:
      return {
        ...state,
        searchLoading: false,
      };
    case STOP_GENERATION_LOADING:
      return {
        ...state,
        generationLoading: false,
      };
    case RESET_IMAGE_UPLOAD_STATE:
      return {
        ...state,
        ...payload,
      };
    case DELETE_IMAGE_SET:
      return {
        ...state,
        deletedImgId: payload,
      };
    case SET_IMAGE_GENERATION_TEXT:
      return {
        ...state,
        imageGenerationText: payload
      };
    case SET_IMAGE_SEARCH_TEXT:
      return {
        ...state,
        imageSearchText: payload
      };
    case IMAGE_ADDED_TO_TABLE:
      let addNodesWithImage = [...state.tableNodesWithImages, payload];
      addNodesWithImage = Array.from(new Set(addNodesWithImage));
      return {
        ...state,
        tableNodesWithImages: addNodesWithImage
      };
    case IMAGE_REMOVED_FROM_TABLE:
      let removeNodesWithImage = state.tableNodesWithImages
      removeNodesWithImage = removeNodesWithImage.filter((n: string) => n !== payload)
      return {
        ...state,
        tableNodesWithImages: removeNodesWithImage
      };
    case RESET_TABLE_IMAGES:
      return {
        ...state,
        tableNodesWithImages: []
      };
    default:
      return state;
  }
}
