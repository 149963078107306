import { useCallback, useMemo, useState } from 'react';
import {
  DataGrid,
  enUS,
  deDE,
  frFR,
  GridFilterModel,
  GridCallbackDetails,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { HistorySelector } from 'src/redux/translationHistory/selector';
import PaginationComp from './PaginationComp';
import CustomeToolbar from './CustomeToolbar';
import { columns } from './columns';
import { updateJobValue } from 'src/redux/translationHistory/actions';
import { FilterValue } from 'src/types/transHistory.types';
import { useNavigate } from 'react-router-dom';
import { emptyStateValueTrans } from 'src/redux/translation/actions';
import { useTheme } from '@mui/material';
import { configSelector } from 'src/redux/appSetting/selector';
import { updateHistorySetting } from 'src/redux/appSetting/actions';
import React from 'react';

type Props = {
  onDeleteEntry: (row: any, alertTrigger: boolean) => void;
};

const HistoryTable = ({ onDeleteEntry }: Props) => {
  const { t, i18n } = useTranslation(['common', 'easy_language', 'A11y'], {
    nsMode: 'fallback',
  });
  const [selectedRow, setSlectedRow] = useState<number>(0);

  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [activeId, setActiveId] = useState('');

  const jobsList = useSelector(
    (state: any) => HistorySelector(state).jobsList,
    shallowEqual
  );

  const isFetchingJobs = useSelector(
    (state: any) => HistorySelector(state).loading,
    shallowEqual
  );

  const historyTransSettingLang = useSelector(
    (state: any) => configSelector(state).historyTransSetting.langLevel,
    shallowEqual
  );

  const historyTransSettingFilter = useSelector(
    (state: any) => configSelector(state).historyTransSetting.filters,
    shallowEqual
  );

  const entries = useSelector(
    (state: any) => HistorySelector(state).entries,
    shallowEqual
  );

  const languageLocaleMapping: { [key: string]: any } = {
    en: enUS,
    de: deDE,
    fr: frFR,
  };

  const handleCellClick = useCallback((params: any) => {
    setSlectedRow(params.row.id);
  }, []);

  const handleConfirmFIlter = useCallback(
    (data: FilterValue) => {
      dispatch(
        updateHistorySetting({
          filters: data as any,
        })
      );
    },
    [dispatch]
  );
  const onFilterChange = useCallback(
    (model: GridFilterModel, details: GridCallbackDetails<'filter'>) => {
      dispatch(
        updateHistorySetting({
          filters: {
            field: model?.items[0]?.field ?? '',
            value: '',
            operation: model?.items[0]?.operator ?? '',
            actualValue: '',
          },
        })
      );
    },
    [dispatch]
  );

  const handleClearFilter = useCallback(() => {
    dispatch(
      updateHistorySetting({
        filters: {
          field: historyTransSettingFilter?.field ?? '',
          operation: historyTransSettingFilter?.operation ?? '',
          value: '',
          actualValue: '',
        },
      })
    );
  }, [dispatch, historyTransSettingFilter]);

  const handleSetNewJob = useCallback(
    (row: any) => {
      dispatch(updateJobValue(row));
      dispatch(emptyStateValueTrans({ transjob: { jobId: '-1' } }));
      navigate('/easy-language');
    },
    [dispatch, navigate]
  );

  const tabeleColumns = useMemo(() => {
    return columns(
      activeId,
      setActiveId,
      historyTransSettingFilter,
      handleConfirmFIlter,
      t,
      handleSetNewJob,
      onDeleteEntry,
      selectedRow,
      dispatch
    );
  }, [
    activeId,
    dispatch,
    handleConfirmFIlter,
    handleSetNewJob,
    historyTransSettingFilter,
    onDeleteEntry,
    selectedRow,
    t,
  ]);

  return (
    <div
      style={{ width: '100%', backgroundColor: theme.palette.general.white }}
      aria-label={t('history_table')}
    >
      <DataGrid
        localeText={
          languageLocaleMapping[i18n.language].components.MuiDataGrid
            .defaultProps.localeText
        }
        // autoHeight
        rows={
          Object.values(jobsList)
            ?.reverse()
            .filter((item: any) =>
              ['', 'none'].includes(historyTransSettingLang!)
                ? true
                : item?.job_task === historyTransSettingLang
            ) ?? []
        }
        autoHeight
        columns={tabeleColumns}
        rowHeight={250}
        getRowHeight={(row) => {
          if (row?.id === activeId) {
            return 520;
          } else {
            return null;
          }
        }}
        onRowClick={(params: any, event: any) => {
          if (!event.ignore) {
            setActiveId(params?.row?.id);
          }
        }}
        onCellClick={handleCellClick}
        sx={{
          cursor: 'pointer',
          overflowX: 'auto',
          overflowY: 'auto',
          '& .MuiDataGrid-cell--editing': {
            boxShadow: 'none !important',
          },
          '& .MuiDataGrid-cell': {
            justifyContent: 'space-around',
            height: 'fit-content',
          },
          '& .Mui-selected .MuiDataGrid-cell': {
            height: 'auto',
          },
          '& .MuiButtonBase-root': {
            color: theme.palette.primary.main,
          },
          '& .MuiDataGrid-columnSeparator': {
            opacity: '0 !important',
          },
          '& .MuiDataGrid-columnHeader--filtered': {
            display: 'none',
          },
        }}
        onCellKeyDown={(params, e, details) => {
          e.stopPropagation();
        }}
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        rowCount={Number(entries)}
        slots={{
          pagination: PaginationComp,
          toolbar: CustomeToolbar,
        }}
        slotProps={{
          filterPanel: {
            filterFormProps: {
              deleteIconProps: {
                onClick: handleClearFilter,
              },
            },

            sx: {
              '& .MuiFormControl-root': {
                justifyContent: ['action_inputs', 'category'].includes(
                  historyTransSettingFilter?.field ?? ''
                )
                  ? 'end'
                  : 'normal',
              },
            },
          },
        }}
        initialState={{
          filter: {
            filterModel: {
              items: [
                {
                  field: historyTransSettingFilter?.field ?? 'originaltext',
                  operator: historyTransSettingFilter?.operation ?? 'contains',
                  value: historyTransSettingFilter?.value ?? '',
                },
              ],
            },
          },
        }}
        loading={isFetchingJobs}
        disableColumnMenu
        onFilterModelChange={onFilterChange}
        autoPageSize={true}
      />
    </div>
  );
};

export default React.memo(HistoryTable);
