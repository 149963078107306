export const UPDATE_JOB_STATUS = 'UPDATE_JOB_STATUS';
export const TRANSLATE_LANG = 'TRANSLATE_LANG';
export const NEW_LINE_SET = 'NEW_LINE_SET';
export const SET_TRANS_CONFIG = 'SET_TRANS_CONFIG';
export const GET_TRANS_CONFIG = 'GET_TRANS_CONFIG';
export const SHOW_REFRESH_PAGE_ALERT = 'SHOW_REFRESH_PAGE_ALERT';
export const LOADING_TRANS = 'LOADING_TRANS';
export const STOP_LOADING_TRANS = 'STOP_LOADING_TRANS';
export const EMPTY_STATE_VALUE = 'EMPTY_STATE_VALUE_TRANS';
export const UPDATE_TEXT_VALUE = 'UPDATE_TEXT_VALUE';
export const UPDATE_SELECTED_OUTPUT_NODE = 'UPDATE_SELECTED_OUTPUT_NODE';
export const UPDATE_EMPTY_INPUT_VALUE = 'UPDATE_EMPTY_INPUT_VALUE';
export const AUTO_LANG_DETECT = 'AUTO_LANG_DETECT';
export const FILE_EXTRACT_TEXT = 'FILE_EXTRACT_TEXT';
export const REGENERATE_TRANSLATION = 'REGENERATE_TRANSLATION';
export const SET_IMAGE_PAGE_VISIBILITY = 'SET_IMAGE_PAGE_VISIBILITY';
export const SET_TRANSLATION_PAGE_LOADING = 'SET_TRANSLATION_PAGE_LOADING';
