import React, { Dispatch, SetStateAction } from 'react';
import { Form } from 'react-bootstrap';
import { FeedbackText } from '../../fixedData';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';

type Props = {
  updateSimpleTextUserEdited: (value: FeedbackText) => void;
  setShowTooltipOutput: (value: boolean) => void;
  setShowFeedbackButtons: (value: boolean) => void;
  setShowSubmitAdjustedButton: Dispatch<SetStateAction<boolean>>;
};

const AdjustButtonComp = ({
  updateSimpleTextUserEdited,
  setShowTooltipOutput,
  setShowFeedbackButtons,
  setShowSubmitAdjustedButton,
}: Props) => {
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  return (
    <Form.Group
      className="feedbackContainer"
      style={{
        paddingLeft: !matchesMD ? '24px' : 0,
      }}
    >
      <Tooltip
        children={
          <IconButton
            // className="mt-2"
            onClick={() => {
              setShowSubmitAdjustedButton(false);
              setShowFeedbackButtons(true);
              setShowTooltipOutput(false);
            }}
            sx={{
              background: theme.palette.general.white,
              color: theme.palette.primary.main,
              textTransform: 'Capitalize',
              borderRadius: '5px',
              marginRight: 1,
              '&:hover': {
                background: theme.palette.general.white,
                color: theme.palette.primary.main,
              },
            }}
          >
            {/* {t('back', { ns: 'common' })} */}
            <ArrowBackIcon
              sx={{
                fontSize: '1.2rem',
                // fill: 'none',
                stroke: theme.palette.primary.main,
                // strokeWidth: 1,
              }}
            />
          </IconButton>
        }
        title={t('back', { ns: 'common' })}
        arrow
        placement="top"
      />

      <Tooltip
        children={
          <IconButton
            // className="mt-2"
            onClick={() => {
              updateSimpleTextUserEdited('adjusted');
              setShowTooltipOutput(false);
            }}
            sx={{
              background: theme.palette.general.white,
              color: theme.palette.primary.main,
              textTransform: 'Capitalize',
              borderRadius: '5px',

              '&:hover': {
                background: theme.palette.general.white,
                color: theme.palette.primary.main,
              },
            }}
          >
            {/* {t('feedback.edit.submitSuggestion')} */}
            <CheckIcon
              sx={{
                fontSize: '1.2rem',
                fill: 'none',
                stroke: theme.palette.primary.main,
                strokeWidth: 2,
              }}
            />
          </IconButton>
        }
        title={t('feedback.edit.submitSuggestion')}
        arrow
        placement="top"
      />
    </Form.Group>
  );
};

export default AdjustButtonComp;
