import LoadingButton from '@mui/lab/LoadingButton';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { isBoolean, isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import InputComponent from 'src/coreUI/inputComp/InputComponent';
import PasswordInput from 'src/coreUI/passwordInput/PasswordInput';
import { signUpAction } from 'src/redux/supabase/actions';
import { supabaseSelector } from 'src/redux/supabase/selector';
import { appMessageHandling } from 'src/utils/errorHandler';
import { setMatomoUid } from 'src/utils/matomo';

type Props = {};

const SignUpField = (props: Props) => {
  const { t } = useTranslation(['common', 'account'], {
    nsMode: 'fallback',
  });
  const theme = useTheme();
  const history = useNavigate();
  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [signupValue, setSignupValue] = useState({
    email: '',
    password: '',
    passwordConfirm: '',
    termasAndDataPrivacy: false,
  });
  const { email, password, passwordConfirm, termasAndDataPrivacy } =
    signupValue;
  const [error, setError] = useState({
    passwordError: '',
    emailError: '',
  });
  const { emailError, passwordError } = error;

  const supabaseError = useSelector(
    (state: any) => supabaseSelector(state).error,
    shallowEqual
  );

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | any
  ) => {
    const { name, value } = e.target;
    setSignupValue({ ...signupValue, [name]: value });
  };

  async function handleSubmit() {
    let pattern = /(?=.*[0-9a-zA-Z]).{6,}/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (password !== passwordConfirm) {
      return setError({
        ...error,
        passwordError: t('signupForm.passwordsDontMatch'),
      });
    } else if (!pattern.test(password)) {
      return setError({
        ...error,
        passwordError: t('signupForm.minPasswordLength'),
      });
    } else if (!emailRegex.test(email)) {
      return setError({ ...error, emailError: t('signupForm.invalidEmail') });
    }
    if (
      termasAndDataPrivacy &&
      !isEmpty(email) &&
      !isEmpty(password) &&
      !isEmpty(passwordConfirm)
    ) {
      setLoading(true);
      let failedSignup = await dispatch(
        signUpAction(email.toLocaleLowerCase(), password)
      );
      setLoading(false);
      if (!isBoolean(failedSignup)) return;
      if (failedSignup || supabaseError) {
        return appMessageHandling(dispatch, 'error.notCreatedError', 'error');
      } else {
        sessionStorage.setItem('email', email.toLocaleLowerCase());

        // Check if user in purches flow or in signup flow
        history('/confirm');
        if (localStorage.getItem('IN_PURCHASE_FLOW') !== 'true') {
          localStorage.setItem('signup_form', 'true');
        }
        setMatomoUid(email.toLocaleLowerCase());
      }
    }
  }

  return (
    <Grid
      item
      container
      xs={12}
      spacing={3}
    >
      <Grid
        item
        xs={12}
      >
        <InputComponent
          value={email}
          name="email"
          handleChange={handleChange}
          label={t('account.email')}
          require={true}
          style={{ width: '100%' }}
          type="email"
          error={!isEmpty(emailError)}
          errorMsg={emailError}
          id="email"
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <PasswordInput
          value={password}
          name="password"
          handleChange={handleChange}
          label={t('signupForm.password')}
          style={{ width: '100%', m: 0 }}
          size="small"
          error={!isEmpty(passwordError)}
          errorMsg={passwordError}
          id={'password'}
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <PasswordInput
          value={passwordConfirm}
          name="passwordConfirm"
          handleChange={handleChange}
          label={t('signupForm.passwordConfirm')}
          style={{ width: '100%', m: 0 }}
          size="small"
          error={!isEmpty(passwordError)}
          errorMsg={passwordError}
          id={'password-confirm'}
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <FormControlLabel
          id="agreePrivacyPolicy"
          control={
            <Checkbox
              sx={{
                '& .MuiSvgIcon-root': {
                  fill: theme.palette.primary.main,
                },
              }}
            />
          }
          onChange={(
            event: React.SyntheticEvent<Element, Event>,
            checked: boolean
          ) => {
            handleChange({
              target: {
                name: 'termasAndDataPrivacy',
                value: checked.toString(),
              },
            });
          }}
          value={termasAndDataPrivacy}
          label={
            <Typography
              variant="body2"
              sx={{
                display: 'inline-block',
                color: theme.palette.primary.main,
              }}
            >
              {t('signupForm.readPrivacyPolicy', {
                interpolation: { escapeValue: false },
              })}
            </Typography>
          }
          sx={{
            width: '100%',
            textAlign: 'left',
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <LoadingButton
          loadingIndicator={t('translationbutton.loading')}
          type="submit"
          loading={loading}
          fullWidth
          variant="contained"
          disabled={
            isEmpty(email) ||
            isEmpty(password) ||
            isEmpty(passwordConfirm) ||
            !termasAndDataPrivacy
          }
          sx={{
            textTransform: 'capitalize',
            p: '8px 3em',

            '&:disabled': {
              color: theme.palette.secondary.main,
              backgroundColor: theme.palette.primary.main,
            },
          }}
          id="otp-submit"
          onClick={handleSubmit}
        >
          {t('signupForm.register')}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default SignUpField;
