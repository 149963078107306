import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Grow,
  IconButton,
  useTheme,
  Box,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { menuListItem, actionButton } from './fixedData';
import MenuListComp from '../menuComp/MenuListComp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useTranslation } from 'react-i18next';
import { ImageUploadItem } from '../../components/imageSearchComp/image.types';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import { shallowEqual, useSelector } from 'react-redux';

type Props = {
  imageItem:
    | ImageUploadItem
    | null
    | { images: { uuid: string }[]; is_owner: boolean };
  handleDelete?: (id: string) => void;
  handleDownload?: (id: string) => void;
  handleFeedback?: (id: string, feedback: 0 | 1 | 2) => void;
  handleInsertImage?: (url: string) => void;
  cols?: number;
  rowHeight?: number;
  imageListStyle?: object;
  disableInsertImage?: boolean;
  curentPage?: number;
  deletedImgId: string | null;
  imagePage: boolean | true;
};

const ImageListItemView = ({
  imageItem,
  handleDelete,
  handleDownload,
  handleFeedback,
  handleInsertImage,
  cols = 3,
  rowHeight = 200,
  imageListStyle = {},
  disableInsertImage = false,
  curentPage = 1,
  deletedImgId,
  imagePage,
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation(['gallery'], {
    nsMode: 'fallback',
  });
  const [viewActions, setViewActions] = useState<string | number>('');

  const rootComponentRef = useRef<HTMLDivElement>(null); // Ref for the component

  let imageSize = 10;
  if (imagePage){
    imageSize = 12;
  }

  const onClick = (item: any) => {
    if (!handleInsertImage){
      return;
    }

    handleInsertImage(
      item.content_base64
        ? item.content_base64
        : `${process.env.REACT_APP_DJANGO_BACKEND_DOMAIN}images/proxy/${item.uuid}`
    )
  }

  return (
    <Box ref={rootComponentRef}>
      <ImageList
        sx={{
          width: '100%',
          height: '100%',
          ...imageListStyle,
        }}
        cols={cols}
        rowHeight={rowHeight}
      >
        {(imageItem && Object.values(imageItem?.images))
          ?.sort((a: any, b: any) => b.similarity - a.similarity)
          .map(
            (item: {
              uuid: string;
              similarity?: number;
              feedback?: 0 | 1 | 2;
              content_base64?: string;
            }) => (
              <Box
                sx={{
                  position: 'relative',
                }}
                key={item.uuid}
              >
                <LoadingOverlay
                  loading={deletedImgId === item.uuid}
                  children={
                    <ImageListItem
                      style={{
                        height: `${imageSize}rem`,
                        width: `${imageSize}rem`,
                        padding: "0 10px",
                        filter:
                          deletedImgId === item.uuid
                            ? 'opacity(50%)'
                            : 'opacity(100%)',
                      }}
                      id={item.uuid}
                      onMouseOver={() => setViewActions(item.uuid)}
                      onMouseLeave={() => setViewActions('')}
                      onClick={() => onClick(item)}
                    >
                      {item.content_base64 ? (
                        <img
                          src={`data:image/png;base64,${item.content_base64}`}
                          alt={item.uuid}
                          loading="lazy"
                          style={{
                            objectFit: 'contain',
                            width: '100%',
                            height: 'auto',
                            aspectRatio: '1 / 1',
                          }}
                        />
                      ) : (
                        <img
                          srcSet={`${process.env.REACT_APP_DJANGO_BACKEND_DOMAIN}images/proxy/${item.uuid}`}
                          src={`${process.env.REACT_APP_DJANGO_BACKEND_DOMAIN}images/proxy/${item.uuid}`}
                          alt={item.uuid}
                          loading="lazy"
                          style={{
                            objectFit: 'contain',
                            width: '100%',
                            height: 'auto',
                            aspectRatio: '1 / 1',
                            background: 'white',
                          }}
                        />
                      )}

                      {imageItem?.is_owner && (
                        <ImageListItemBar
                          sx={{
                            background: 'transparent',
                            padding: '5px'
                          }}
                          actionPosition="right"
                          position="top"
                          actionIcon={
                            <MenuListComp
                              list={menuListItem(t, item, handleDelete).list}
                              menuView={
                                <MoreHorizIcon
                                  sx={{
                                    fontSize: '16px',
                                    color: theme.palette.primary.main,
                                  }}
                                />
                              }
                              title={t('img_opt')}
                              type="iconButton"
                              menuStyle={{
                                '.MuiMenu-list': {
                                  paddingBottom: 0,
                                  paddingTop: 0,
                                },
                              }}
                              buttonstyle={{
                                color: theme.palette.general.goldenYellow,
                                p: '3px',
                                ml: '5px',
                                borderRadius: '11%',
                                background: `${theme.palette.secondary.main}`,
                                '&:hover': {
                                  color: theme.palette.general.goldenYellow,
                                  background: `${theme.palette.secondary.main}`,
                                },
                              }}
                            />
                          }
                        />
                      )}
                      <Grow in={item.uuid === viewActions}>
                        <ImageListItemBar
                          actionPosition="right"
                          position="bottom"
                          title={undefined}
                          sx={{
                            background: 'transparent',
                            padding: '5px',
                            justifyContent: 'right',
                            '& .MuiImageListItemBar-titleWrap': {
                              display: 'none',
                            },
                          }}
                          actionIcon={actionButton(
                            handleDownload,
                            item,
                            handleFeedback,
                            handleInsertImage
                          ).map(({ Icon, action, label }) => (
                            <IconButton
                              key={label}
                              sx={{
                                color: theme.palette.primary.main,
                                p: '3px',
                                ml: '5px',
                                borderRadius: '11%',
                                background: `${theme.palette.secondary.main}`,
                                '&:hover': {
                                  color: theme.palette.primary.main,
                                  background: `${theme.palette.secondary.main}`,
                                },
                              }}
                              aria-label={`${label} ${item.uuid}`}
                              onClick={() => {
                                action();
                              }}
                              disabled={
                                label === 'insert image'
                                  ? disableInsertImage
                                  : false
                              }
                            >
                              <Icon sx={{ fontSize: '16px' }} />
                            </IconButton>
                          ))}
                        />
                      </Grow>
                    </ImageListItem>
                  }
                />
              </Box>
            )
          ) ?? <></>}
      </ImageList>
    </Box>
  );
};

export default ImageListItemView;
