import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { FileEarmarkFont, Image } from 'react-bootstrap-icons';
import styles from '../TextareaField/TextAreaField.module.css';
import theme from '../../theme/themeConfig';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { UserSelector } from '../../redux/user/selector';
import Tooltip from '@mui/material/Tooltip';

type Props = {
  switchStep: (value: boolean) => void;
  addImage: boolean;
};

const TranslationStepSwitch = ({ switchStep, addImage }: Props) => {
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  const matchesSM = useMediaQuery(theme.breakpoints.down('md'));

  const canAddImage = useSelector(
    (state: any) => UserSelector(state).canAddImage,
    shallowEqual
  );

  return (
    <Grid
      container
      alignItems="center"
      sx={{
        height: '7rem',
        backgroundColor: theme.palette.primary.main,
        p: 3,
        ...(matchesSM
          ? {}
          : {
              paddingLeft: '12px',
              paddingRight: '12px',
            }),
      }}
    >
      <Grid
        item
        container
        xs={6}
        sx={{
          backgroundColor: theme.palette.primary.main,
          padding: matchesSM ? 'auto' : '0 1.5%',
        }}
      >
        <Grid
          item
          xs="auto"
        >
          <Button
            onClick={() => switchStep(false)}
            className={`${styles['sourceButton']} ${styles['flowStepButton']} ${
              addImage ? '' : styles['active']
            }`}
            sx={{
              textTransform: 'capitalize',
            }}
          >
            <div>
              <FileEarmarkFont className={styles['sourceButtonIcon']} />
            </div>
            <div>{t('steps.translate')}</div>
          </Button>
        </Grid>
        <Grid
          item
          xs="auto"
        >
          {canAddImage ? (
            <Button
              onClick={() => switchStep(true)}
              className={`${styles['sourceButton']} ${
                styles['flowStepButton']
              } ${addImage ? styles['active'] : ''}`}
            >
              <div>
                <Image className={styles['sourceButtonIcon']} />
              </div>
              <div>{t('steps.addImage')}</div>
            </Button>
          ) : (
            <Tooltip
              title={t('steps.disabledTooltip')}
              componentsProps={{
                tooltip: {
                  sx: {
                    maxWidth: '400px',
                    padding: '8px 12px',
                    fontSize: '14px',
                  },
                },
              }}
            >
              <span>
                <Button
                  onClick={() => switchStep(true)}
                  className={`${styles['sourceButton']} ${
                    styles['flowStepButton']
                  } ${addImage ? styles['active'] : ''} ${styles['inactive']}`}
                  disabled={true}
                >
                  <div>
                    <Image className={styles['sourceButtonIcon']} />
                  </div>
                  <div>{t('steps.addImage')}</div>
                </Button>
              </span>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TranslationStepSwitch;
