import { LoadingButton } from '@mui/lab';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { newUserSignup } from 'src/redux/user/actions';
import { UserSelector } from 'src/redux/user/selector';
import { isEmpty } from 'lodash';
import { supabaseSelector } from 'src/redux/supabase/selector';
import { useNavigate } from 'react-router-dom';
import { Grid, SelectChangeEvent, useTheme } from '@mui/material';
import InputComponent from 'src/coreUI/inputComp/InputComponent';
import MaterialSelectComp from 'src/coreUI/materialSelect/MaterialSelectComp';
import { categoryoptions } from './fixedData';

type Props = {};

const CompanyField = (props: Props) => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'A11y'], {
    nsMode: 'fallback',
  });
  const history = useNavigate();

  const supabaseUser = useSelector(
    (state: any) => supabaseSelector(state).user,
    shallowEqual
  );

  const [companyFrom, setCompanyForm] = useState({
    forename: '',
    surname: '',
    companyName: '',
    category: 'translator',
    categoryFreeText: '',
    terms_accepted: true,
    data_privacy_accepted: true,
    email: supabaseUser?.email,
  });
  const { forename, surname, companyName, category, categoryFreeText } =
    companyFrom;

  const [showCategoryFreeText, setShowCategoryFreeText] =
    useState<boolean>(false);

  const dispatch: any = useDispatch();

  const loading = useSelector(
    (state: any) => UserSelector(state).loading,
    shallowEqual
  );

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setCompanyForm({ ...companyFrom, [name]: value });
  };

  async function handleSubmit() {
    // store all data before signing up
    const categoryText = category === 'other' ? categoryFreeText : category;
    if (
      !isEmpty(forename) &&
      !isEmpty(surname) &&
      !isEmpty(companyName) &&
      !isEmpty(categoryText)
    ) {
      const newUserData = {
        forename: forename,
        surname: surname,
        category: categoryText,
        company_name: companyName,
        terms_accepted: true,
        data_privacy_accepted: true,
        email: localStorage.getItem('SIGNUP_EMAIL'),
      };
      if (localStorage.getItem('signup_form')) {
        await dispatch(newUserSignup(newUserData, history));
      }
    }
  }

  const handleRoleChange = (e: SelectChangeEvent<string>) => {
    if (e?.target.value === 'other') {
      setShowCategoryFreeText(true);
    } else {
      setShowCategoryFreeText(false);
    }
  };

  return (
    <Grid
      item
      container
      xs={12}
      spacing={3}
    >
      <Grid
        item
        xs={12}
      >
        <InputComponent
          value={forename}
          name="forename"
          handleChange={handleChange}
          label={t('signupForm.firstName')}
          require={true}
          style={{ width: '100%' }}
          id="forename"
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <InputComponent
          value={surname}
          name="surname"
          handleChange={handleChange}
          label={t('signupForm.lastName')}
          require={true}
          style={{ width: '100%' }}
          id="surname"
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <InputComponent
          value={companyName}
          name="companyName"
          handleChange={handleChange}
          label={t('signupForm.companyName')}
          require={true}
          style={{ width: '100%' }}
          id="companyName"
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <MaterialSelectComp
          menuItem={categoryoptions(t)}
          handleChange={(e) => {
            handleChange(e);
            handleRoleChange(e);
          }}
          value={category}
          name={'category'}
          id={'categorySelect'}
          formStyle={{ width: '100%', m: 0, mt: 0, textAlign: 'left' }}
          style={{ width: '100%' }}
          require={true}
        />
      </Grid>
      {showCategoryFreeText && (
        <Grid
          item
          xs={12}
        >
          <InputComponent
            value={categoryFreeText}
            name="categoryFreeText"
            handleChange={handleChange}
            label={t('signupForm.specifyRole')}
            require={true}
            style={{ width: '100%' }}
            id="categoryFreeText"
          />
        </Grid>
      )}
      <Grid
        item
        xs={12}
      >
        <LoadingButton
          loadingIndicator={t('translationbutton.loading')}
          type="button"
          loading={loading}
          variant="contained"
          fullWidth
          sx={{
            textTransform: 'none',
            p: '8px 3em',
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
            },
            '&:disabled': {
              color: theme.palette.secondary.main,
              backgroundColor: theme.palette.primary.main,
            },
          }}
          id="otp-submit"
          onClick={handleSubmit}
        >
          {t('signupForm.complete_register')}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default CompanyField;
