import React from 'react';
import { Input as BaseInput, InputProps } from '@mui/base/Input';
import { styled } from '@mui/system';
import { props } from 'cypress/types/bluebird';

type Props = {
  disabled?: boolean;

  // multiline should be true if called together with minRows and maxRows
  multiline?: boolean | any;
  minRows?: number;
  maxRows?: number;

  placeholder?: string;
  label?: string;
  value?: string;
  handleChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  defaultValue?: string;
  style?: object;
  name?: string;
};

const Input = React.forwardRef(function CustomInput(
  props: InputProps & { sx?: object; helloStyle: object },
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <BaseInput
      slots={{
        root: RootDiv,
        input: 'input',
        textarea: TextareaElement,
      }}
      {...props}
      ref={ref}
    />
  );
});

const blue = {
  100: '#DAECFF',
  200: '#80BFFF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const RootDiv = styled('div')`
  display: flex;
  max-width: 100%;
`;

const TextareaElement = styled('textarea', {
  shouldForwardProp: (prop) =>
    !['ownerState', 'minRows', 'maxRows'].includes(prop.toString()),
})(
  ({ theme, ownerState }: any) => `
  width: ${ownerState.textareaStyle?.width ?? '320px'} ;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  padding: 8px 12px;
  border-radius: 8px 8px 8px 8px;
  margin-top: ${ownerState.textareaStyle?.marginTop ?? 0};
  color: ${
    ownerState.textareaStyle?.color ?? theme.palette.mode === 'dark'
      ? grey[300]
      : grey[900]
  };
  background: ${
    ownerState.textareaStyle?.backgroundColor ?? theme.palette.mode === 'dark'
      ? grey[900]
      : '#fff'
  };
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 4px ${
    theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
  };
  
  &:hover {
    border-color: #fff;
  };
  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === 'dark' ? blue[700] : blue[200]
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);
const TextareaComp = ({
  disabled = false,
  minRows = 3,
  maxRows = 3,
  placeholder = '',
  label = 'Feld für Zusammenfassung',
  value,
  handleChange,
  defaultValue = '',
  multiline = true,
  style = {},
  name,
}: Props) => {
  return (
    <Input
      aria-label={label}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      readOnly={disabled}
      multiline={multiline}
      {...(multiline && { minRows, maxRows })}
      textareaStyle={style}
      name={name}
    />
  );
};

export default TextareaComp;
