import React from 'react';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

type Props = {
  children: any;
  title: string;
  placement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
    | undefined;
  style?: object;
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: '1rem',
    '& :before': {
      color: theme.palette.common.white,
    },
  },
}));

const TooltipComp = ({
  children,
  title,
  placement = 'left',
  style = {},
}: Props) => {
  return (
    <LightTooltip
      title={title}
      arrow={true}
      placement={placement}
      sx={style}
    >
      <span>{children}</span>
    </LightTooltip>
  );
};

export default TooltipComp;
