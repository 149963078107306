import { Dispatch, SetStateAction, useState } from 'react';
import { regenerateFeedbackOpt } from '../../fixedData';
import ShowNefFeedbackInputComp from './ShowNefFeedbackInputComp';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { regeneratedTransFeedback } from 'src/redux/translation/actions';
import { TranslateSelector } from 'src/redux/translation/selector';

type Props = {
  showFeedbackModal: boolean;
  setShowFeedbackModal: Dispatch<SetStateAction<boolean>>;
};

const ShowRegenerateFeedback = (props: Props) => {
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  const theme = useTheme();
  const dispatch: any = useDispatch();
  const [showregeneFeedbackInput, setShowregeneFeedbackInput] =
    useState<boolean>(false);
  const [otherFeedback, setOtherFeedback] = useState<string>('');

  const regeneratedJobs = useSelector(
    (state: any) => TranslateSelector(state).regeneratedJobs,
    shallowEqual
  );

  const handleConfirm = (value: string) => {
    let feedback;
    if (value === 'other') {
      feedback = otherFeedback;
    } else {
      feedback = value;
    }

    dispatch(
      regeneratedTransFeedback({
        regenerated_reason: feedback,
        id: regeneratedJobs[1].jobId,
      })
    );

    props.setShowFeedbackModal(false);
  };

  return (
    <Box sx={{ mt: '2em', textAlign: 'left', paddingLeft: '24px' }}>
      <Typography
        variant="subtitle2"
        gutterBottom
        sx={{
          color: theme.palette.general.white,
          fontSize: '1rem',
        }}
      >
        {t('feedback.regerate.question')}
      </Typography>
      {regenerateFeedbackOpt(t).map((item) => (
        <Button
          key={item.id}
          variant="outlined"
          onClick={() =>
            item.value !== 'other'
              ? handleConfirm(item.value)
              : setShowregeneFeedbackInput(true)
          }
          sx={{
            color: theme.palette.general.white,
            borderColor: theme.palette.general.white,
            textTransform: 'capitalize !important',
            margin: '5px',
            '&:hover': {
              borderColor: theme.palette.general.white,
            },
          }}
        >
          {item.text}
        </Button>
      ))}

      {showregeneFeedbackInput === true && (
        <ShowNefFeedbackInputComp
          label={t('feedback.negative.giveReason')}
          handleConfirm={handleConfirm}
          setOtherFeedback={setOtherFeedback}
          value={otherFeedback}
        />
      )}
    </Box>
  );
};

export default ShowRegenerateFeedback;
