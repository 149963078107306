import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import SynonymsField from '@summ-ai-github/summ-frontend-package/components/synonymsFieldComp';
import {
  Book,
  ArrowLeftRight,
  TextLeft,
  CardImage,
} from 'react-bootstrap-icons';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import TooltipComp from '../../coreUI/tooltipsComp/TooltipComp';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  emptyStateValueSynonyms,
  getSynonymsData,
} from 'src/redux/synonyms/actions';
import { refreshSession } from 'src/redux/supabase/actions';
import { SynonymsSelector } from 'src/redux/synonyms/selector';
import { sideBarTab } from 'src/redux/appSetting/actions';
import FeatureComp from 'src/coreUI/featureComp/FeatureComp';
import { FeatureSelector } from 'src/redux/features/selector';
import { updateFeature } from 'src/redux/features/actions';
import { UserSelector } from 'src/redux/user/selector';
import { emptyStateValueImageUpload } from '../../redux/addImage/actions';
import GlossaryFieldsWrapper from '../GlossaryField/GlossaryFieldsWrapper';
import { emptyStateValueGlossary } from 'src/redux/glossary/actions';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Drawer } from './sidebarStyle';
import UntranslatedDictionaryWrapper from '../UntranslatedDisctionary/UntranslatedDictionaryWrapper';
import CollectionsBookmarkRoundedIcon from '@mui/icons-material/CollectionsBookmarkRounded';
import { emptyStateValueuntranslatedWord } from 'src/redux/untranslatedWord/actions';
import { configSelector } from 'src/redux/appSetting/selector';
import { TranslateSelector } from 'src/redux/translation/selector';
import SummaryCompWrapper from '../SummaryComp/SummaryCompWrapper';
import ImageUploadCompWrapper from '../ImageUpload/ImageUploadCompWrapper';

type Props = {
  pageBody: React.ReactNode;
  inputText: string;
  outputText: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  outputTextValue: any;
};

const SidebarDrawerComp = ({
  pageBody,
  inputText,
  outputText,
  open,
  setOpen,
}: // outputTextValue,
Props) => {
  const theme = useTheme();
  const dispatch: any = useDispatch();
  const matchesSM = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation(
    ['easy_language', 'synonym', 'common', 'summary', 'A11y'],
    {
      nsMode: 'fallback',
    }
  );

  useEffect(() => {
    dispatch(sideBarTab(null));
  }, [dispatch]);

  const sidebarTabValue = useSelector(
    (state: any) => configSelector(state).sidebarTab,
    shallowEqual
  );

  const userDetails = useSelector(
    (state: any) => UserSelector(state).userDetails,
    shallowEqual
  );

  const synonymsList = useSelector(
    (state: any) => SynonymsSelector(state).synonymsList,
    shallowEqual
  );

  const featuresList = useSelector(
    (state: any) => FeatureSelector(state).featuresList,
    shallowEqual
  );

  const loadingSynonys = useSelector(
    (state: any) => SynonymsSelector(state).loading,
    shallowEqual
  );

  const transConfig = useSelector(
    (state: any) => TranslateSelector(state).transConfig,
    shallowEqual
  );

  const autoLangDetect = useSelector(
    (state: any) => TranslateSelector(state).autoLangDetect,
    shallowEqual
  );

  const showImagePage = useSelector(
    (state: any) => TranslateSelector(state).showImagePage,
    shallowEqual
  );

  useEffect(() => {
    setOpen(false);
    dispatch(sideBarTab(null));
  }, [showImagePage]);

  useEffect(() => {
    if (
      (transConfig?.preferred_translation_language?.[
        `preferred_trans_language_${userDetails?.preferred_output_language_type}`
      ] === 'fr' ||
        autoLangDetect.value === 'fr') &&
      open
    ) {
      setOpen(false);
      dispatch(sideBarTab(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoLangDetect, dispatch, transConfig?.preferred_translation_language]);

  const handleDrawerOpen = () => {
    setOpen(true);
    dispatch(sideBarTab(0));
  };

  const handleDrawerClose = () => {
    dispatch(emptyStateValueSynonyms({ synonymsList: null }));
    dispatch(emptyStateValueImageUpload({ imageList: null }));
    dispatch(
      emptyStateValueGlossary({
        glossaryItems: { difficultWords: [] },
        highLightWord: {},
        AIWordExplanation: [],
        hardWords: [],
      })
    );
    dispatch(
      emptyStateValueuntranslatedWord({
        ownWordList: null,
        companyWordList: null,
      })
    );
    dispatch(sideBarTab(null));
    setOpen(false);
  };

  const handleChange = useCallback(
    (event: any, newValue: number) => {
      if (!open) {
        setOpen(true);
      }
      // setValue(newValue);
      dispatch(sideBarTab(newValue));

      dispatch(emptyStateValueImageUpload({ imageList: null }));
      // dispatch(emptyStateValueImageUpload({ generatedImages: null }));
      dispatch(emptyStateValueSynonyms({ synonymsList: null }));
      dispatch(
        emptyStateValueGlossary({
          glossaryItems: { difficultWords: [] },
          highLightWord: {},
          AIWordExplanation: [],
          hardWords: [],
        })
      );
      dispatch(
        emptyStateValueuntranslatedWord({
          ownWordList: null,
          companyWordList: null,
        })
      );
      // dispatch(updateSelectedOutputNode(null));
    },
    [dispatch, open, setOpen]
  );

  type MenuOption = {
    id: string;
    Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
    order: number;
    controlId: string;
    Element: React.ReactNode;
    featureId?: number;
  };

  const menuOptions: MenuOption[] = useMemo(
    () =>
      [
        {
          id: 'glossary',
          Icon: Book,
          order: 0,
          Element: (
            <GlossaryFieldsWrapper
              outputText={inputText + ' ' + outputText}
              switchValue={false}
            />
          ),
          controlId: 'glossarButton',
          featureId: 1,
        },
        {
          id: 'synonyms',
          Icon: ArrowLeftRight,
          order: 1,
          Element: (
            <SynonymsField
              getSynonymsData={(originalRange: string) =>
                dispatch(getSynonymsData(originalRange))
              }
              emptyStateValueSynonyms={(data: { synonymsList: [] | null }) =>
                dispatch(emptyStateValueSynonyms(data))
              }
              refreshSession={() => dispatch(refreshSession())}
              synonymsList={synonymsList}
              generalAlertMessage={t('alert.general')}
              noWordEnteredAlertMessage={t('alert.noWordEntered')}
              multipleWordEnteredAlertMessage={t('alert.multipleWordsEntered')}
              inputPlaceholder={t('enterWord')}
              loadingText={t('loading', { ns: 'common' })}
              sourceText={t('source')}
              loadSynonymsText={t('search')}
              loading={loadingSynonys}
              copiedText={t('copied')}
              copyText={t('copy')}
              emptyListText={t('emptySynonyms')}
              style={{
                textTransform: 'initial',
              }}
            />
          ),
          controlId: 'synonymButton',
          featureId: 2,
        },
        {
          id: 'summary',
          Icon: TextLeft,
          order: 2,
          Element: <SummaryCompWrapper inputTextValue={inputText} />,
          controlId: 'summaryButton',
          featureId: 3,
        },
        {
          id: 'imageUpload',
          Icon: CardImage,
          order: 3,
          Element: <ImageUploadCompWrapper/>,
          controlId: 'imageUploadButton',
          featureId: 4,
        },
        userDetails?.previewFeatures
          ? {
              id: 'wordsEntities',
              Icon: CollectionsBookmarkRoundedIcon,
              order: 4,
              Element: <UntranslatedDictionaryWrapper />,
              controlId: 'wordEntriesButton',
              featureId: 6,
            }
          : null,
      ].filter((item) => item !== null) as MenuOption[],
    [
      dispatch,
      inputText,
      loadingSynonys,
      outputText,
      synonymsList,
      t,
      userDetails?.previewFeatures,
    ]
  );

  const isWideScreen = useMediaQuery('(min-width:790px)')

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <CssBaseline />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: theme.palette.primary.main,
          ...(matchesSM
            ? {}
            : {
                paddingLeft: '12px',
                paddingRight: '12px',
              }),
        }}
      >
        {pageBody}
      </Box>
      {!showImagePage && (
        <Drawer
          variant="permanent"
          open={open}
          anchor="right"
          id="sidebar-drawer"
          transitionDuration={300}
          sx={{
            // flexShrink: 0,]
            display: 'flex',
            alignItems: 'stretch',
            // height: '100%',
            overflowY: 'auto',
            width: isWideScreen ? '30%' : '50%',
            '& .MuiDrawer-paper': {
              position: 'relative !important',
              boxSizing: 'border-box',
              borderLeft: 'none',
              width: '100%',
            },
            '&.MuiDrawer-docked': {
              ...(open
                ? {}
                : {
                    width: 'calc(44px + 1px)',
                  }),
            },
          }}
        >
          <Grid
            item
            container
            xs={12}
          >
            <Grid
              item
              lg={open ? 'auto' : 1.2}
              sm={2}
              xs={2}
              sx={{
                borderRight: open
                  ? `1px solid ${theme.palette.general.paleBlue}`
                  : 'none',
                boxShadow: open ? theme.shadows[2] : 'none',
              }}
              className="sideMenu-wrapper"
            >
              {!open ? (
                <IconButton
                  aria-label={t('open_sidebar')}
                  onClick={handleDrawerOpen}
                  edge="start"
                  id={'sideBarMenuButton'}
                  sx={{
                    color: theme.palette.primary.main,
                    background: theme.palette.general.goldenYellow,
                    px: open ? 2 : 2.7,
                    py: 1.3,
                    borderRadius: 0,
                  }}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                >
                  <ChevronLeftIcon
                    width={30}
                    height={30}
                    // sx={{
                    //   color: theme.palette.general.goldenYellow,
                    // }}
                  />
                </IconButton>
              ) : (
                <IconButton
                  onClick={handleDrawerClose}
                  id={'sideBarMenuButton'}
                  sx={{
                    pl: 0,
                    px: 1.5,
                    color: theme.palette.primary.main,
                  }}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  aria-label={t('close_sidebar')}
                >
                  {theme.direction === 'rtl' ? (
                    <ChevronLeftIcon
                      width={26}
                      height={26}
                    />
                  ) : (
                    <ChevronRightIcon
                      width={26}
                      height={26}
                    />
                  )}
                </IconButton>
              )}
              {/* my displayed icons */}

              {menuOptions.map(({ id, Icon, order, controlId, featureId }) => (
                <ListItem
                  disablePadding
                  sx={{ display: 'block' }}
                  aria-label={controlId}
                  key={id}
                  id={controlId}
                >
                  <TooltipComp title={t(id)}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: 'center',
                        borderTop: open
                          ? `1px solid ${theme.palette.general.paleBlue}`
                          : 'none',
                        px: open ? 0.3 : 2.7,
                        background:
                          sidebarTabValue === order
                            ? theme.palette.general.goldenYellow
                            : 'auto',
                        '&:hover': {
                          background:
                            sidebarTabValue === order
                              ? theme.palette.general.goldenYellow
                              : 'auto',
                        },
                      }}
                      onClick={() => {
                        if (
                          featuresList &&
                          featureId &&
                          !featuresList[featureId]?.seen
                        ) {
                          dispatch(updateFeature(featureId));
                        }
                        handleChange(null, order);
                      }}
                      disabled={
                        id === 'synonyms' &&
                        (transConfig?.preferred_translation_language?.[
                          `preferred_trans_language_${userDetails?.preferred_output_language_type}`
                        ] === 'fr' ||
                          autoLangDetect.value === 'fr')
                      }
                    >
                      <FeatureComp
                        show={
                          featuresList && featureId
                            ? featuresList[featureId]?.seen
                            : true
                        }
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            m: 'auto',
                            justifyContent: 'center',
                            color:
                              order === sidebarTabValue
                                ? theme.palette.primary.main
                                : theme.palette.primary.main,
                            fontSize: '1.5rem !important',
                          }}
                        >
                          <Icon
                            width={id === 'summary' ? 26 : 20}
                            height={id === 'summary' ? 26 : 20}
                          />
                        </ListItemIcon>
                      </FeatureComp>
                      {/* <ListItemText
                        primary={id}
                        sx={{ opacity: open ? 1 : 0 }}
                      /> */}
                    </ListItemButton>
                  </TooltipComp>
                </ListItem>
              ))}
            </Grid>
            {open && (
              <Grid
                item
                container
                lg={true}
                sm={10}
                xs={10}
                sx={{
                  height: document
                    .querySelector('.sideMenu-wrapper')
                    ?.getBoundingClientRect().height,
                  overflowY: 'auto',
                  display: 'block',
                  minHeight: '100%',
                }}
              >
                <Grid
                  item
                  xs={12}
                  pr={1.5}
                  pl={1}
                >
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    p={'6px'}
                    textAlign={'left'}
                    fontSize={'1.2rem'}
                    sx={{
                      color: theme.palette.primary.main,
                      fontWeight: 'bold',
                    }}
                  >
                    {t(menuOptions[sidebarTabValue || 0]?.id)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  pr={1.5}
                  pl={1.5}
                  // pt={1}
                >
                  {menuOptions[sidebarTabValue || 0]?.Element}
                  {/* <TabsComp
                  tabs={menuOptions}
                  handleChange={handleChange}
                  value={value}
                /> */}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Drawer>
      )}
    </Box>
  );
};

export default SidebarDrawerComp;
