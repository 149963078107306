import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CLEAR_EDITOR_COMMAND, $getRoot } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useDispatch } from 'react-redux';
import { emptyStateValueTrans } from 'src/redux/translation/actions';
import { updateJobValue } from 'src/redux/translationHistory/actions';
import { t } from 'i18next';
type Props = {};

const ClearInputPlugin = (props: Props) => {
  const [editor] = useLexicalComposerContext();
  const dispatch: any = useDispatch();
  const handleClearInput = () => {
    editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
    editor.focus();
    dispatch(
      emptyStateValueTrans({ transjob: { jobId: '-1' }, regeneratedJobs: [] })
    );
    dispatch(updateJobValue(null));
    setTimeout(() => {
      editor.update(() => {
        $getRoot().selectStart();
      });
    }, 100);
  };
  return (
    <IconButton
      aria-label={t('clear_input', { ns: 'A11y' })}
      onClick={handleClearInput}
      sx={{
        position: 'absolute',
        top: '0',
        right: '0',
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

export default ClearInputPlugin;
