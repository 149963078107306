import GetAppIcon from '@mui/icons-material/GetApp';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { Button, SvgIconTypeMap } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import React from 'react';
import themeConfig from '../../theme/themeConfig';

export const actionButton: (
  handleDownload?: (id: string) => void,
  item?: any,
  handleFeedback?: (id: string, feedback: 0 | 1 | 2) => void,
  handleInsertImage?: (id: string) => void
) => {
  action: any;
  label: string;
  Icon: any;
}[] = (handleDownload, item, handleFeedback, handleInsertImage) => [
  ...(handleFeedback
    ? [
        // ...(handleInsertImage
        //   ? [
        //       {
        //         action: () =>
        //           handleInsertImage(
        //             item.content_base64
        //               ? item.content_base64
        //               : `${process.env.REACT_APP_DJANGO_BACKEND_DOMAIN}images/proxy/${item.uuid}`
        //           ),
        //         label: 'insert image',
        //         Icon: PlaylistAddIcon,
        //       },
        //     ]
        //   : []),
      ]
    : []),
  ...(handleDownload && !item.content_base64
    ? [
        {
          action: () => handleDownload(item.uuid),
          label: 'download',
          Icon: GetAppIcon,
        },
      ]
    : []),
];

export const menuListItem = (
  t: any,
  item: any,
  handleDelete?: (id: string) => void
) => {
  return {
    list: handleDelete
      ? [
          {
            id: 'image-upload-1',
            value: (
              <>
                <Button
                  sx={{
                    padding: '0',
                    textTransform: 'lowercase',
                    fontSize: '.9rem',
                    color: themeConfig.palette.primary.main,
                    '&:hover': {
                      background: 'none',
                    },
                  }}
                  onClick={() => handleDelete(item.uuid)}
                  startIcon={
                    <DeleteIcon
                      sx={{
                        fontSize: '16px',
                        color: themeConfig.palette.primary.main,
                      }}
                    />
                  }
                >
                  {t('delete')}
                </Button>
              </>
            ),
          },
        ]
      : [],
  };
};
