import { Dispatch, SetStateAction, useState } from 'react';
import { FeedbackText, negativeFeedbackOpt } from '../../fixedData';
import ShowNefFeedbackInputComp from './ShowNefFeedbackInputComp';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography, useTheme } from '@mui/material';

type Props = {
  showFeedbackModal: boolean;
  setShowFeedbackModal: Dispatch<SetStateAction<boolean>>;
  updateSimpleTextUserEdited: (
    value: FeedbackText,
    userFeedbackText?: string
  ) => void;
};

const ShowNegativeFeedback = (props: Props) => {
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  const theme = useTheme();
  const [showNegFeedbackInput, setShowNegFeedbackInput] =
    useState<boolean>(false);
  const [otherFeedback, setOtherFeedback] = useState<string>('');

  const handleConfirm = (value: string) => {
    let feedback;
    if (value === 'other') {
      feedback = otherFeedback;
    } else {
      feedback = value;
    }
    props.updateSimpleTextUserEdited('negative', feedback);
    props.setShowFeedbackModal(false);
  };

  return (
    <Box sx={{ mt: '2em', textAlign: 'left', paddingLeft: '24px' }}>
      <Typography
        variant="subtitle2"
        gutterBottom
        sx={{
          color: theme.palette.general.white,
          fontSize: '1rem',
        }}
      >
        {t('feedback.negative.reasonQuestion')}
      </Typography>
      {negativeFeedbackOpt(t).map((item) => (
        <Button
          key={item.id}
          variant="outlined"
          onClick={() =>
            item.value !== 'other'
              ? handleConfirm(item.value)
              : setShowNegFeedbackInput(true)
          }
          sx={{
            color: theme.palette.general.white,
            borderColor: theme.palette.general.white,
            margin: '5px',
            '&:hover': {
              borderColor: theme.palette.general.white,
            },
          }}
        >
          {item.text}
        </Button>
      ))}

      {showNegFeedbackInput === true && (
        <ShowNefFeedbackInputComp
          handleConfirm={handleConfirm}
          label={t('feedback.negative.giveReason')}
          setOtherFeedback={setOtherFeedback}
          value={otherFeedback}
        />
      )}
    </Box>
  );
};

export default ShowNegativeFeedback;
