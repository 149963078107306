import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_CRITICAL,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_NORMAL,
  KEY_MODIFIER_COMMAND,
  $getRoot,
} from 'lexical';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { $getSelectionStyleValueForProperty } from '@lexical/selection';
import {
  $getNearestNodeOfType,
  mergeRegister,
  $findMatchingParent,
} from '@lexical/utils';
import { $isListNode, ListNode } from '@lexical/list';
import { $isHeadingNode } from '@lexical/rich-text';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import style from '../TextStyle.module.css';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import {
  blockTypeToBlockName,
  fileExportTypes,
  supportedBlockTypes,
} from '../fixedData';
import FontDropDown from '../features/FontDropDown';
import BlockOptionsDropdownList from '../features/BlockOptionsDropdownList';
import { getSelectedNode } from '../utils/getSelectnode';
import { sanitizeUrl } from '../utils/url';
import { Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SettingsIcon from '@mui/icons-material/Settings';
import SwitchComp from 'src/coreUI/switchComp/SwitchComp';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import MenuListComp from 'src/coreUI/menuComp/MenuListComp';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import { $generateHtmlFromNodes } from '@lexical/html';
import { generateDocxFromHtml } from 'src/utils/htmlConverter/wordConverter';
import { generateRTFFromHtml } from 'src/utils/htmlConverter/RTFConverter';
import { generateTXTFromHtml } from 'src/utils/htmlConverter/TXTConvertor';
import ConfirmModelComp from 'src/coreUI/confirmModelComp/ConfirmModelComp';
import InputComponent from 'src/coreUI/inputComp/InputComponent';
import { isEmpty } from 'lodash';

const LowPriority = 1;

function Divider() {
  return <div className={style['divider']} />;
}

export default function ToolbarPlugin({
  refHolder,
  refEditor,
  showToolbar,
  handleCopyText,
  featureInclude,
  toolbarStyle,
  noAnimationSlider,
  handleShowSetting,
  settingComponent,
  showSetting,
  handleSwitch,
  switchInput,
  switchChecked,
  showSwitch,
  showFormatIcon,
  disabled,
}: any) {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'easy_language', 'A11y'], {
    nsMode: 'fallback',
  });
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);
  // const toolbarRef = useRef(null);
  const [, setCanUndo] = useState(false);
  const [, setCanRedo] = useState(false);
  const [blockType, setBlockType] = useState('paragraph');
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);

  const [checked, setChecked] = useState(true);
  const [fontSize, setFontSize] = useState<string>('15px');
  const [fontFamily, setFontFamily] = useState<string>('Times New Roman');
  const [showClipboardTooltipCopy, setShowClipboardTooltipCopy] =
    useState(true);

  const [filename, setFilename] = useState('summ-ai');
  const [openNameModel, setOpenNameModel] = useState(false);
  const [fileType, setFileType] = useState<'word' | 'rtf' | 'txt'>('word');

  const getTheHtml = (fileType: 'word' | 'rtf' | 'txt') => {
    editor.update(() => {
      const editorState = editor.getEditorState();

      editorState.read(() => {
        let root = $generateHtmlFromNodes(editor, null);
        // add border none for table row and cell

        if (fileType === 'word') generateDocxFromHtml(root, filename);
        if (fileType === 'rtf') generateRTFFromHtml(root, filename);
        if (fileType === 'txt') {
          let textContent = $getRoot().getTextContent();
          generateTXTFromHtml(textContent, filename);
        }
      });
    });
  };

  const handleSetFilename = (value: string) => {
    setFilename(value);
  };
  const handleConfirm = () => {
    getTheHtml(fileType);
    setOpenNameModel(false);
    setFilename('summ-ai');
  };
  const handleTriggerDownload = (type: 'word' | 'rtf' | 'txt') => {
    setFileType(type);
    setOpenNameModel(true);
  };

  useEffect(() => {
    if (!showClipboardTooltipCopy) {
      setTimeout(() => {
        setShowClipboardTooltipCopy(true);
      }, 1500);
    }
  }, [showClipboardTooltipCopy]);

  const checkExclude = useCallback(
    (value: string) => {
      return featureInclude.includes(value) || featureInclude.includes('All');
    },
    [featureInclude]
  );

  useEffect(() => {
    if (refEditor !== undefined) {
      refEditor.current = editor;
    }
  }, [editor, refEditor]);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey: any = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      //   setIsStrikethrough(selection.hasFormat('strikethrough'));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }

      if (elementDOM !== null) {
        // setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(
            anchorNode,
            ListNode
          );
          const type = parentList
            ? parentList.getListType()
            : element.getListType();
          setBlockType(type);
        } else if (!$isListNode(element)) {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();

          if (type in supportedBlockTypes) {
            setBlockType(type as keyof typeof blockTypeToBlockName);
          }
        }
      }
      // Handle buttons
      setFontSize(
        $getSelectionStyleValueForProperty(selection, 'font-size', '15px')
      );
      setFontFamily(
        $getSelectionStyleValueForProperty(
          selection,
          'font-family',
          'Times New Roman'
        )
      );
    }
  }, [activeEditor]);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        updateToolbar();
        setActiveEditor(newEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, updateToolbar]);

  useEffect(() => {
    return mergeRegister(
      editor?.registerUpdateListener(({ editorState }: any) => {
        editorState?.read(() => {
          updateToolbar();
        });
      }),
      editor?.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload: any, newEditor: any) => {
          updateToolbar();
          return false;
        },
        LowPriority
      ),
      editor?.registerCommand(
        CAN_UNDO_COMMAND,
        (payload: any) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority
      ),
      editor?.registerCommand(
        CAN_REDO_COMMAND,
        (payload: any) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  const optionRef: any = useRef(null);

  useEffect(() => {
    // Make my list scroll to the begining
    if (optionRef && optionRef.current) {
      optionRef.current.scrollLeft = optionRef.current.offsetWidth;
    }
  }, [optionRef]);

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl('https://'));
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  useEffect(() => {
    return activeEditor.registerCommand(
      KEY_MODIFIER_COMMAND,
      (payload: any) => {
        const event: KeyboardEvent = payload;
        const { code, ctrlKey, metaKey } = event;

        if (code === 'KeyK' && (ctrlKey || metaKey)) {
          event.preventDefault();
          return activeEditor.dispatchCommand(
            TOGGLE_LINK_COMMAND,
            sanitizeUrl('https://')
          );
        }
        return false;
      },
      COMMAND_PRIORITY_NORMAL
    );
  }, [activeEditor, isLink]);

  return (
    <div
      className={style['toolbar']}
      // ref={toolbarRef}
      style={{
        justifyContent: (
          showSwitch ? switchInput === 'on' : showToolbar && !showSetting
        )
          ? 'space-between'
          : 'end',
        zIndex: 1,
        background: 'transparent !important',
      }}
    >
      {(showSwitch ? switchInput === 'on' : showToolbar && !showSetting) && (
        <Box
          className={style['toolbar-container']}
          style={{ height: '42px', background: 'transparent !important' }}
          ref={optionRef}
        >
          {/* <Slide
            in={noAnimationSlider || checked}
            direction={'left'}
            container={refHolder.current}
          > */}
          <Paper
            sx={{
              width: '100%',
              height: 'fit-content',
              display: 'flex',
              justifyContent: 'space-between',
              background: 'transparent !important',
              ...toolbarStyle,
            }}
            elevation={0}
          >
            {checkExclude('undo') && (
              <IconButton
                disabled={!disabled}
                onClick={() => {
                  activeEditor.dispatchCommand(UNDO_COMMAND, undefined);
                }}
                className={style['toolbar-item'] + ' ' + style['spaced']}
                aria-label={t('undo')}
              >
                <i className={style['format'] + ' ' + style['undo']} />
              </IconButton>
            )}
            {checkExclude('redo') && (
              <>
                <IconButton
                  disabled={!disabled}
                  onClick={() => {
                    activeEditor.dispatchCommand(REDO_COMMAND, undefined);
                  }}
                  className={style['toolbar-item']}
                  aria-label={t('redo')}
                >
                  <i className={style['format'] + ' ' + style['redo']} />
                </IconButton>
                <Divider />
              </>
            )}
            {checkExclude('blockOption') && (
              <>
                <BlockOptionsDropdownList
                  editor={editor}
                  blockType={blockType}
                  // toolbarRef={toolbarRef}
                  // options={blockTypeToBlockName}
                  disabled={!disabled}
                />
                {/* <Divider /> */}
              </>
            )}
            <>
              {/* {checkExclude('fontFamily') && (
                <FontDropDown
                  type="font-family"
                  value={fontFamily}
                  editor={editor}
                  selectStyle={{
                    maxWidth: '62px',
                    minWidth: '68px',
                    marginRight: '7px',
                    fontSize: '.8rem',
                    '& .MuiOutlinedInput-input': {
                      fontSize: '.8rem',
                      padding: '5px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none !important',
                    },
                  }}
                  disabled={!disabled}
                />
              )} */}
              {checkExclude('fontSize') && (
                <FontDropDown
                  type="font-size"
                  value={fontSize}
                  editor={editor}
                  selectStyle={{
                    minWidth: '65px',
                    '& .MuiOutlinedInput-input': {
                      fontSize: '.8rem',
                      padding: '5px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none !important',
                    },
                  }}
                  disabled={!disabled}
                />
              )}
              {checkExclude('bold') && (
                <IconButton
                  onClick={() => {
                    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
                  }}
                  className={
                    style['toolbar-item'] +
                    ' ' +
                    style['spaced'] +
                    ' ' +
                    (isBold ? style['active'] : '')
                  }
                  aria-label={t('format_bold')}
                  disabled={!disabled}
                  sx={{
                    height: '36px !important',
                    width: '34px',
                  }}
                >
                  <Typography
                    className={style['format'] + ' ' + style['bold']}
                    variant="subtitle2"
                    sx={{
                      fontSize: '1rem',
                      fontWeight: 'bold',
                      color: isBold ? '#000' : 'inherit',
                    }}
                  >
                    {t('bold_symbol')}
                  </Typography>
                  {/* <i className={style['format'] + ' ' + style['bold']} /> */}
                </IconButton>
              )}
              {checkExclude('italic') && (
                <IconButton
                  onClick={() => {
                    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
                  }}
                  className={
                    style['toolbar-item'] +
                    ' ' +
                    style['spaced'] +
                    ' ' +
                    (isItalic ? style['active'] : '')
                  }
                  aria-label={t('format_italics')}
                  disabled={!disabled}
                  sx={{
                    height: '36px !important',
                    width: '34px',
                  }}
                >
                  <Typography
                    className={style['format'] + ' ' + style['italic']}
                    variant="subtitle2"
                    sx={{
                      fontSize: '1.3rem',
                      // fontWeight: 'bold',
                      color: isItalic ? '#000' : 'inherit',
                    }}
                  >
                    {t('italic_symbol')}
                  </Typography>
                  {/* <i className={style['format'] + ' ' + style['italic']} /> */}
                </IconButton>
              )}
              {checkExclude('underline') && (
                <>
                  <IconButton
                    onClick={() => {
                      activeEditor.dispatchCommand(
                        FORMAT_TEXT_COMMAND,
                        'underline'
                      );
                    }}
                    className={
                      style['toolbar-item'] +
                      ' ' +
                      style['spaced'] +
                      ' ' +
                      (isUnderline ? style['active'] : '')
                    }
                    aria-label={t('format_underline')}
                    disabled={!disabled}
                  >
                    <i className={style['format'] + ' ' + style['underline']} />
                  </IconButton>
                  {/* <Divider /> */}
                </>
              )}
              {checkExclude('leftAlign') && (
                <IconButton
                  onClick={() => {
                    activeEditor.dispatchCommand(
                      FORMAT_ELEMENT_COMMAND,
                      'left'
                    );
                  }}
                  className={style['toolbar-item'] + ' ' + style['spaced']}
                  aria-label={t('left_align')}
                  disabled={!disabled}
                >
                  <i className={style['format'] + ' ' + style['left-align']} />
                </IconButton>
              )}
              {checkExclude('centerAlign') && (
                <IconButton
                  onClick={() => {
                    activeEditor.dispatchCommand(
                      FORMAT_ELEMENT_COMMAND,
                      'center'
                    );
                  }}
                  className={style['toolbar-item'] + ' ' + style['spaced']}
                  aria-label={t('center_align')}
                  disabled={!disabled}
                >
                  <i
                    className={style['format'] + ' ' + style['center-align']}
                  />
                </IconButton>
              )}
              {checkExclude('justifyAlign') && (
                <IconButton
                  onClick={() => {
                    activeEditor.dispatchCommand(
                      FORMAT_ELEMENT_COMMAND,
                      'justify'
                    );
                  }}
                  className={style['toolbar-item']}
                  aria-label={t('justify_align')}
                  disabled={!disabled}
                >
                  <i
                    className={style['format'] + ' ' + style['justify-align']}
                  />
                </IconButton>
              )}{' '}
              {checkExclude('insertLink') && (
                <IconButton
                  onClick={insertLink}
                  className={
                    style['toolbar-item'] +
                    ' ' +
                    style['spaced'] +
                    ' ' +
                    (isLink ? style['active'] : '')
                  }
                  aria-label={t('insert_link')}
                  title={t('insert_link')}
                  disabled={!disabled}
                >
                  <i className={style['format'] + ' ' + style['link']} />
                </IconButton>
              )}
            </>
          </Paper>
          {/* </Slide> */}
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          zIndex: 1,
          height: 'fit-content',
          paddingBottom: '5px',
        }}
      >
        {showFormatIcon && (
          <span>
            <Tooltip
              title={t('format')}
              placement="top"
            >
              <span>
                {showSwitch ? (
                  <SwitchComp
                    handleSwitch={handleSwitch}
                    switchInput={switchInput}
                    checked={switchChecked}
                    disabled={!disabled}
                    label={
                      !switchChecked ? t('disable_format') : t('enable_format')
                    }
                  />
                ) : (
                  <IconButton
                    onClick={handleChange}
                    sx={{
                      color: checked
                        ? theme.palette.primary.main
                        : theme.palette.general.cyanBlue,
                      '&:hover': {
                        color: theme.palette.primary.main,
                      },
                    }}
                    disabled={!disabled}
                    aria-label={t('switch_formatting')}
                  >
                    <FormatColorTextIcon />
                  </IconButton>
                )}
              </span>
            </Tooltip>
          </span>
        )}

        {settingComponent && (
          <Tooltip
            title={t('options')}
            placement="top"
          >
            <IconButton
              onClick={handleShowSetting}
              sx={{
                background: theme.palette.secondary.main,
                color: showSetting
                  ? theme.palette.primary.main
                  : theme.palette.general.grayishBlue,
                borderRadius: '5px',
                marginLeft: '5px',
                height: '37px',
                '&:hover': {
                  color: theme.palette.primary.main,
                  background: theme.palette.secondary.main,
                },
              }}
              disabled={!disabled}
              aria-label={t('show_setting')}
            >
              <SettingsIcon />
            </IconButton>
          </Tooltip>
        )}
        {checkExclude('export') && !showSetting && (
          <Tooltip
            title={showClipboardTooltipCopy ? t('export') : t('exported')}
            placement="top"
          >
            <span>
              <MenuListComp
                list={fileExportTypes({ t, getTheHtml: handleTriggerDownload })}
                menuView={
                  <FileDownloadOutlinedIcon
                    sx={{
                      color: theme.palette.general.grayishBlue,
                      '&:hover': {
                        color: theme.palette.primary.main,
                      },
                    }}
                  />
                }
                menuStyle={{
                  '& .MuiMenu-list': {
                    padding: 0,
                  },
                }}
                type="iconButton"
                name={t('export_file')}
                disable={!disabled}
                buttonstyle={{
                  borderRadius: '5px',
                  background: theme.palette.secondary.main,
                  color: theme.palette.general.cyanBlue,
                  marginLeft: '5px',
                  height: '37px',
                  width: '37px',
                  '&:hover': {
                    color: theme.palette.primary.main,
                    background: theme.palette.secondary.main,
                  },
                }}
              />
            </span>
          </Tooltip>
        )}
        {checkExclude('copy') && !showSetting && (
          <Tooltip
            title={showClipboardTooltipCopy ? t('copy') : t('copied')}
            placement="top"
          >
            <span>
              <IconButton
                onClick={() => {
                  setShowClipboardTooltipCopy(false);
                  handleCopyText();
                }}
                sx={{
                  borderRadius: '5px',
                  background: theme.palette.secondary.main,
                  color: theme.palette.general.cyanBlue,
                  marginLeft: '5px',
                  height: '37px',
                  '&:hover': {
                    color: theme.palette.primary.main,
                    background: theme.palette.secondary.main,
                  },
                }}
                disabled={!disabled}
                aria-label={t('copy_text')}
              >
                <ContentCopyIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
        <ConfirmModelComp
          open={openNameModel}
          setOpen={setOpenNameModel}
          handleConfirm={handleConfirm}
          title={t('enter_file_name')}
          disableConfirm={isEmpty(filename)}
          component={
            <InputComponent
              value={filename}
              label=""
              id="filename"
              handleChange={(e) => handleSetFilename(e?.target.value as string)}
              require={true}
              style={{
                width: '100%',
                padding: '5px',
                borderRadius: '5px',
              }}
            />
          }
        />
      </Box>
    </div>
  );
}
