import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { refreshSession } from 'src/redux/supabase/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FeedbackText, feedbackTexts } from '../../fixedData';
import AdjustButtonComp from './AdjustButtonComp';
import FeedbackButtonsComp from './FeedbackButtonsComp';
import { updateTransJob } from 'src/redux/translation/actions';
import { triggerAlert } from 'src/redux/appSetting/actions';
import SaveTransHistoryUpdate from '../SaveTransHistoryUpdate';
import { TranslateSelector } from 'src/redux/translation/selector';
import { $generateHtmlFromNodes } from '@lexical/html';

type Props = {
  outputText: any;
  outputTextValue: any;
  jobId: string;
  setShowFeedbackButtons: (value: boolean) => void;
  setShowTooltipOutput: (value: boolean) => void;
  setDisableInputField: (value: boolean) => void;
  // setShowInfoBorderOutput: (value: boolean) => void;
  outputRefEditor: any;
  showFeedbackButtons: boolean;
  historyJob: object | null;
};
const UserFeedback = (props: Props) => {
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  // states
  const [showSubmitAdjustedButton, setShowSubmitAdjustedButton] =
    useState<boolean>(false);
  const [feedbackText, setFeedbackText] = useState<string>(
    `${t('feedback.thanks')} 😊`
  );

  const [transIndex, setTransIndex] = useState<number>(1);
  const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false);
  const [showRegenerateModal, setShowRegenerateModal] =
    useState<boolean>(false);
  const dispatch: any = useDispatch();

  const transjob = useSelector(
    (state: any) => TranslateSelector(state).transjob,
    shallowEqual
  );

  useEffect(() => {
    setShowFeedbackModal(false);
    setShowRegenerateModal(false);
  }, []);
  // functions
  const updateSimpleTextUserEdited = async (
    userFeedback: FeedbackText,
    userFeedbackText = ''
  ) => {
    setFeedbackText(feedbackTexts(t)[userFeedback]);
    // trackEvent({ category: 'feedback', action: userFeedback });
    // refresh firebase token before sending request

    dispatch(refreshSession());
    // check html
    // check if input text contains html tags (currently handling only links and bolding)
    let isHtmlTextInput = false;
    let htmlText = props.outputText;
    if (
      props.outputText.includes('<strong') ||
      props.outputText.includes('<a')
    ) {
      isHtmlTextInput = true;
    }

    props.outputRefEditor.current?.update(() => {
      htmlText = $generateHtmlFromNodes(props.outputRefEditor.current, null);
    });

    const adjustedText = isHtmlTextInput
      ? htmlText
      : props.outputTextValue.current.text;

    // update with current output value
    const currentSimpleTextUserEdited =
      userFeedback === 'adjusted' ? adjustedText : '';
    let updateUserFeedback =
      userFeedback === 'negative'
        ? `negative:${userFeedbackText}`
        : userFeedback;
    const jsonData = {
      simpleTextUserEdited: currentSimpleTextUserEdited,
      userFeedback: updateUserFeedback,
    };
    dispatch(updateTransJob(transjob.jobId, jsonData));

    // props.setShowFeedbackButtons(false);
    setShowFeedbackModal(false);
    props.setShowTooltipOutput(false);
    props.setDisableInputField(false);
    dispatch(triggerAlert(feedbackText, 'default'));
    // setShowSubmitAdjustedButton(false);
  };
  // ui
  return (
    <>
      {props.showFeedbackButtons && (
        <FeedbackButtonsComp
          // setShowInfoBorderOutput={props.setShowInfoBorderOutput}
          setShowTooltipOutput={props.setShowTooltipOutput}
          outputRefEditor={props.outputRefEditor}
          setShowFeedbackButtons={props.setShowFeedbackButtons}
          setShowSubmitAdjustedButton={setShowSubmitAdjustedButton}
          updateSimpleTextUserEdited={updateSimpleTextUserEdited}
          transIndex={transIndex}
          setTransIndex={setTransIndex}
          showFeedbackModal={showFeedbackModal}
          setShowFeedbackModal={setShowFeedbackModal}
          showRegenerateModal={showRegenerateModal}
          setShowRegenerateModal={setShowRegenerateModal}
        />
      )}
      {showSubmitAdjustedButton && (
        <AdjustButtonComp
          updateSimpleTextUserEdited={updateSimpleTextUserEdited}
          setShowTooltipOutput={props.setShowTooltipOutput}
          setShowFeedbackButtons={props.setShowFeedbackButtons}
          setShowSubmitAdjustedButton={setShowSubmitAdjustedButton}
        />
      )}

      {props.historyJob && transjob.jobId === '-1' && (
        <SaveTransHistoryUpdate />
      )}
    </>
  );
};

export default UserFeedback;
