import { Box, Button, useTheme } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import TextareaComp from 'src/coreUI/TextAreaComp/TextareaComp';

type Props = {
  handleConfirm: (value: string) => void;
  label: string;
  setOtherFeedback: Dispatch<SetStateAction<string>>;
  value: string;
};

const ShowNefFeedbackInputComp = ({
  handleConfirm,
  label,
  setOtherFeedback,
  value,
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  return (
    <Box>
      <TextareaComp
        placeholder={label}
        label={label}
        style={{
          width: '100% !important',
          backgroundColor: 'transparent',
          color: theme.palette.general.white,
          boxShadow: 'none',
          marginTop: '1em',
        }}
        handleChange={(e) => setOtherFeedback(e.target.value)}
        value={value}
      />
      <Button
        disabled={value?.trim() === ''}
        onClick={() => handleConfirm('other')}
        variant="contained"
        sx={{
          textTransform: 'Capitalize',
          float: 'right',
          mt: '1em',
          backgroundColor: theme.palette.general.goldenYellow,
          color: theme.palette.primary.main,
          '&:hover': {
            backgroundColor: theme.palette.general.goldenYellow,
            color: theme.palette.primary.main,
          },
        }}
      >
        {t('submit')}
      </Button>
    </Box>
  );
};

export default ShowNefFeedbackInputComp;
