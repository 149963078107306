import React, { useState } from 'react';
import styles from './GlossaryField.module.css';
import './GlossaryField.css';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Grid,
  Tooltip,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { isEmpty } from 'lodash';
import LoadingButton from '@mui/lab/LoadingButton';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { User, UserDetails } from './common.types';
import { GlossaryData } from './glossary.types';
import InputComponent from '../../coreUI/inputComp/InputComponent';
import TextareaComp from '../../coreUI/TextAreaComp/TextareaComp';

type AddNewGlossaryProps = {
  getFullText: (searchGlossery: boolean, value?: string) => void;
  fetchTags: () => void;
  premiumFeatureAlertWord: string;
  newEntryWord: string;
  wordText: string;
  enterTags: string;
  explanationWord: string;
  enterWord: string;
  userDetails: UserDetails;
  createNewGlossary: (value: GlossaryData) => void;
  supabaseUser: User | null;
  summaryStyle?: object;
  detailsStyle?: object;
};

const AddNewGlossary = (props: AddNewGlossaryProps) => {
  const theme = useTheme();
  const [expandAcc, setExpandAcc] = useState<boolean>(false);
  const [formVal, setFormVal] = useState<{
    word: string;
    tags: string;
    explanation: string;
  }>({
    word: '',
    tags: '',
    explanation: '',
  });

  const { word, tags, explanation } = formVal;

  const [loadingGlossaryEntry, setLoadingGlossaryEntry] = useState(false);
  const [newTags, setNewTags] = useState<string[]>([]);

  // methods
  const createNewGlossaryEntry = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();

    setLoadingGlossaryEntry(true);
    const jsonData: GlossaryData = {
      newentry: {
        word: word || '',
        explanation: explanation || '',
        link_to_source: props.supabaseUser!.email,
        source: props.supabaseUser!.email,
        tags: newTags.join(','),
      },
    };

    // dispatch(refreshSession());
    props.createNewGlossary(jsonData);
    // refetch tags
    props.fetchTags();
    setNewTags([]);
    props.getFullText(false);
    setFormVal({
      word: '',
      tags: '',
      explanation: '',
    });

    setLoadingGlossaryEntry(false);
  };

  function removeTag(index: number) {
    var temp = newTags;
    temp = temp.filter((el, i) => i !== index);
    setNewTags(temp);
  }

  function handleKeyDown(e: any) {
    if (e.key !== 'Enter') return;

    const value: string = e.target.value;

    if (!value.trim()) return;
    setNewTags([...newTags, value]);

    e.target.value = '';
  }

  const handleChange: () => void = () => {
    setExpandAcc(!expandAcc);
  };
  const handleFormUpate = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormVal({ ...formVal, [e.target.name]: e.target.value });
  };
  // UI
  return (
    <Tooltip
      placement="top"
      title={
        props.userDetails?.premiumuser
          ? undefined
          : props.premiumFeatureAlertWord
      }
    >
      <Accordion
        expanded={expandAcc}
        onChange={handleChange}
        disabled={!props.userDetails?.premiumuser}
        disableGutters
        sx={{
          boxShadow: 'none',
          border: `1px solid #DCE2E6`,
          borderRadius: 'none',
          // height: '40px !important',
          '&::before': {
            position: 'unset',
          },
          '& .MuiAccordionSummary-content': {
            margin: '0 !important',
          },
        }}
      >
        {/* <Accordion className="text-left mt-3 mb-4"> */}
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon sx={{ color: theme.palette.secondary.main }} />
          }
          aria-controls="add-new-glossary"
          id="add-new-glossary"
          aria-label="Add new glossary"
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
            borderRadius: expandAcc ? '4px 4px 0 0' : '4px',
            // height: '40px !important',
            minHeight: '40px !important',
            ...props.summaryStyle,
          }}
        >
          <Grid
            item
            xs="auto"
            display={'block'}
          >
            <ControlPointIcon
              fontSize="small"
              sx={{ pt: '0px' }}
            />
            <div
              className={`${styles['Add']} p-2`}
              style={{ display: 'inline-block' }}
            >
              {props.newEntryWord}
            </div>
          </Grid>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            ...props.detailsStyle,
            paddingTop: '16px',
            borderRadius: '0px 0px 4px 4px',
          }}
          id="accordinDetails"
        >
          <Grid
            item
            container
            xs={12}
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <InputComponent
                id="glossary-form-word"
                variant="outlined"
                label={props.wordText}
                style={{
                  width: '100%',
                  borderRadius: '4px',
                  background: '#fff',
                  '& .MuiOutlinedInput-input': {
                    p: '5.5px 13px',
                  },
                  '& .MuiOutlinedInput-input::placeholder': {
                    color: theme.palette.primary.main,
                  },
                  '& .MuiInputLabel-root': {
                    top: isEmpty(word) ? '-5px' : '0px',
                    color: `${theme.palette.primary.main} !important`,
                  },
                  '& .Mui-focused': {
                    top: '0',
                  },
                }}
                handleChange={handleFormUpate}
                name="word"
                value={word}
              />
            </Grid>
            <Grid
              item
              container
              xs={12}
              sx={{ mt: newTags.length > 0 ? '2px' : 0 }}
            >
              {newTags.length > 0 &&
                newTags.map((tag, index) => (
                  <Grid
                    item
                    // xs={'auto'}
                    key={index}
                    sx={{ maxWidth: '45%', mt: '3px', ml: '2px' }}
                  >
                    <Chip
                      label={tag}
                      onDelete={() => removeTag(index)}
                      sx={{
                        height: 'auto',
                        maxHeight: '4em',
                        overflow: 'scroll',
                        borderRadius: '4px',
                        '& .MuiChip-label': {
                          wordWrap: 'break-word',
                        },
                        '& .MuiChip-deleteIcon': {
                          color: theme.palette.primary.main,
                          '&:hover': {
                            color: theme.palette.secondary.main,
                          },
                        },
                        display: 'inline-table',
                      }}
                    />
                  </Grid>
                ))}
              <Grid
                item
                xs={12}
                sx={{ mt: newTags.length > 0 ? '1em' : 0 }}
              >
                <InputComponent
                  id="glossary-form-tags"
                  variant="outlined"
                  label={props.enterTags}
                  style={{
                    width: '100%',
                    borderRadius: '4px',
                    background: '#fff',
                    '& .MuiOutlinedInput-input': {
                      p: '5.5px 13px',
                    },
                    '& .MuiOutlinedInput-input::placeholder': {
                      color: theme.palette.primary.main,
                    },
                    '& .MuiInputLabel-root': {
                      top: isEmpty(tags) ? '-5px' : '0px',
                      color: `${theme.palette.primary.main} !important`,
                    },
                    '& .Mui-focused': {
                      top: '0',
                    },
                  }}
                  handleChange={handleFormUpate}
                  handlekeyUp={handleKeyDown}
                  name="tags"
                  value={tags}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextareaComp
                value={explanation}
                // disabled={true}
                multiline={true}
                maxRows={3}
                minRows={3}
                style={{
                  // height: heightInput - 37,
                  // minHeight: 337 - 37,
                  width: '100%',
                  '& .base-Input-multiline': {
                    width: '100%',
                    fontSize: '1rem',
                    borderRadius: '4px !important',
                    border: `1px solid ${theme.palette.primary.main}`,
                    resize: 'none !important',
                    boxShadow: 'none !important',
                    '&:focus': {
                      outline: 'auto',
                      // boxShadow: 'none !important',
                      borderColor: theme.palette.primary.main,
                    },
                    '&:hover': {
                      // outline: 'none !important',
                      borderColor: theme.palette.primary.main,
                    },
                    '&::placeholder': {
                      color: `${theme.palette.primary.main} !important`,
                    },
                  },
                }}
                handleChange={handleFormUpate}
                name="explanation"
                placeholder={props.explanationWord}
                label={props.explanationWord}
              />
            </Grid>
            <Grid
              item
              xs={12}
              textAlign={'center'}
            >
              <LoadingButton
                aria-disabled={isEmpty(word)}
                disabled={isEmpty(word)}
                loading={loadingGlossaryEntry}
                variant="contained"
                className={'w-50 ' + styles['forgot-pwd-send']}
                type="submit"
                sx={{
                  textTransform: 'capitalize',
                  fontSize: '1rem',
                  color: theme.palette.secondary.main,
                  backgroundColor: theme.palette.primary.main,
                  '&:hover': {
                    backgroundColor: theme.palette.primary.main,
                  },
                  ...(!loadingGlossaryEntry && {
                    '&:disabled': {
                      color: theme.palette.secondary.main,
                      backgroundColor: theme.palette.primary.main,
                    },
                  }),
                }}
                disableElevation
                onClick={createNewGlossaryEntry}
              >
                {props.enterWord}
              </LoadingButton>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Tooltip>
  );
};

export default AddNewGlossary;
